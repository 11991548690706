import { abi } from "thor-devkit"

export const AuctionCreated: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_auctionId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: false,
            name: "_startingPrice",
            type: "uint256",
        },
        {
            indexed: false,
            name: "_endingPrice",
            type: "uint256",
        },
        {
            indexed: false,
            name: "_duration",
            type: "uint64",
        },
    ],
    name: "AuctionCreated",
    type: "event",
}

export const AuctionSuccessful: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_auctionId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_seller",
            type: "address",
        },
        {
            indexed: false,
            name: "_winner",
            type: "address",
        },
        {
            indexed: false,
            name: "_finalPrice",
            type: "uint256",
        },
    ],
    name: "AuctionSuccessful",
    type: "event",
}
export const AddAuctionWhiteList: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_auctionId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_candidate",
            type: "address",
        },
    ],
    name: "AddAuctionWhiteList",
    type: "event",
}
export const RemoveAuctionWhiteList: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_auctionId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_candidate",
            type: "address",
        },
    ],
    name: "RemoveAuctionWhiteList",
    type: "event",
}
export const Transfer: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_from",
            type: "address",
        },
        {
            indexed: true,
            name: "_to",
            type: "address",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "Transfer",
    type: "event",
}
export const Approval: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_owner",
            type: "address",
        },
        {
            indexed: true,
            name: "_approved",
            type: "address",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "Approval",
    type: "event",
}
export const ApprovalForAll: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_owner",
            type: "address",
        },
        {
            indexed: true,
            name: "_operator",
            type: "address",
        },
        {
            indexed: false,
            name: "_approved",
            type: "bool",
        },
    ],
    name: "ApprovalForAll",
    type: "event",
}
export const NewUpgradeApply: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_applier",
            type: "address",
        },
        {
            indexed: false,
            name: "_level",
            type: "uint8",
        },
        {
            indexed: false,
            name: "_applyTime",
            type: "uint64",
        },
        {
            indexed: false,
            name: "_applyBlockno",
            type: "uint64",
        },
    ],
    name: "NewUpgradeApply",
    type: "event",
}
export const CancelUpgrade: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_owner",
            type: "address",
        },
    ],
    name: "CancelUpgrade",
    type: "event",
}
export const LevelChanged: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_owner",
            type: "address",
        },
        {
            indexed: false,
            name: "_fromLevel",
            type: "uint8",
        },
        {
            indexed: false,
            name: "_toLevel",
            type: "uint8",
        },
    ],
    name: "LevelChanged",
    type: "event",
}
export const AuctionCancelled: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "_auctionId",
            type: "uint256",
        },
        {
            indexed: true,
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "AuctionCancelled",
    type: "event",
}
export const ProtocolUpgrade: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: false,
            name: "_saleAuction",
            type: "address",
        },
    ],
    name: "ProtocolUpgrade",
    type: "event",
}
export const OperatorUpdated: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: false,
            name: "_op",
            type: "address",
        },
        {
            indexed: false,
            name: "_enabled",
            type: "bool",
        },
    ],
    name: "OperatorUpdated",
    type: "event",
}
export const BlackListUpdated: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: false,
            name: "_person",
            type: "address",
        },
        {
            indexed: false,
            name: "_op",
            type: "bool",
        },
    ],
    name: "BlackListUpdated",
    type: "event",
}
export const Pause: abi.Event.Definition = {
    anonymous: false,
    inputs: [],
    name: "Pause",
    type: "event",
}
export const Unpause: abi.Event.Definition = {
    anonymous: false,
    inputs: [],
    name: "Unpause",
    type: "event",
}
export const OwnershipTransferred: abi.Event.Definition = {
    anonymous: false,
    inputs: [
        {
            indexed: true,
            name: "previousOwner",
            type: "address",
        },
        {
            indexed: true,
            name: "newOwner",
            type: "address",
        },
    ],
    name: "OwnershipTransferred",
    type: "event",
}
