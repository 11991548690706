/**
 * Check if the current app mode is one of the provided ones
 * @param modes  - the modes to check
 * @returns boolean representing whether the current app mode is one of the provided ones
 */
type Modes = "production" | "development" | "demo" | "staging"
export const isMode = (...modes: Modes[]) => {
    return modes.includes(import.meta.env.MODE as Modes)
}

export const isWorkInProgress = () => {
    return (
        import.meta.env.VITE_WORK_IN_PROGRESS != undefined &&
        import.meta.env.VITE_WORK_IN_PROGRESS === "true"
    )
}
