import { AnalyticsEventsEnum, ApiCallStateEnum } from "@/constants"
import { AnalyticsUtils } from "@/utils"
import Axios, { AxiosError } from "axios"

const axios = Axios.create({
    baseURL: "https://api.coingecko.com/api/v3",
})

export const getCoinGeckoCoins = async () => {
    try {
        const response = await axios.get("/coins/list")
        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.API_CALL, {
            state: ApiCallStateEnum.SUCCESS,
            endpoint: "getCoinGeckoCoins",
        })

        return response.data
    } catch (e) {
        const error = e as AxiosError
        AnalyticsUtils.publishAxiosError(error, "getCoinGeckoCoins")
        throw e
    }
}

export const getCoinGeckoCoin = async (id: string) => {
    try {
        const response = await axios.get(`/coins/${id}`)
        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.API_CALL, {
            state: ApiCallStateEnum.SUCCESS,
            endpoint: "getCoinGeckoCoin",
            id,
        })

        return response.data
    } catch (e) {
        const error = e as AxiosError
        AnalyticsUtils.publishAxiosError(error, "getCoinGeckoCoin")
        throw e
    }
}

type GetExchangeRateResponse = {
    [key: string]: {
        [key: string]: number
    }
}

export const getExchangeRate = async (id: string, vs_currency: string) => {
    try {
        const response = await axios.get<GetExchangeRateResponse>(
            `/simple/price`,
            {
                params: {
                    ids: id,
                    vs_currencies: vs_currency,
                },
            },
        )
        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.API_CALL, {
            state: ApiCallStateEnum.SUCCESS,
            endpoint: "getExchangeRate",
            id,
            vs_currency,
        })

        return response.data[id][vs_currency]
    } catch (e) {
        const error = e as AxiosError
        AnalyticsUtils.publishAxiosError(error, "getCoinGeckoCoin")
        throw e
    }
}
