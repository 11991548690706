import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import "dayjs/locale/en"

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export { dayjs } // OR export default dayjs

/**
 * This is a helper function to format a dayjs duration in a human readable format.
 *
 * @param duration dayjs.duration() object
 * @param type "short" | "long", if short we will use d h m s, if long we will use days hours minutes seconds
 * @returns
 */
export const formatDuration = (
    duration: plugin.Duration,
    type: "short" | "long",
) => {
    const formatText = {
        short: {
            days: "d",
            hours: "h",
            minutes: "m",
            seconds: "s",
        },
        long: {
            days: " days",
            hours: " hours",
            minutes: " minutes",
            seconds: " seconds",
        },
    }

    let formatted = ""
    if (duration.days() > 0) {
        // If the duration is more than 1 day or 1 hour, we will show days, hours and minutes (1d 3h 5m)
        formatted = duration.format(
            `D[${formatText[type].days}] H[${formatText[type].hours}]`,
        )
    } else if (duration.hours() > 0) {
        // If the duration is more than 1 hour but less than 1 day, we will show hours and minutes (3h 5m)
        formatted = duration.format(
            `H[${formatText[type].hours}] m[${formatText[type].minutes}]`,
        )
    } else if (duration.minutes() > 0) {
        // If the duration is less than 1 hour, we will show minutes and seconds ("5m 30s" instead of "0d 0h 5m 30s")
        formatted = duration.format(
            `m[${formatText[type].minutes}] s[${formatText[type].seconds}]`,
        )
    } else {
        // If the duration is less than 1 minute, we will show seconds ("30s" instead of "0d 0h 0m 30s")
        formatted = duration.format(`s[${formatText[type].seconds}]`)
    }

    // We will remove 0 days, 0 hours and 0 minutes (so it will be 3h 5m instead of 0d 3h 5m, or 5s instead of 0m 5s)
    // We will use singular form if the duration is 1 day, 1 hour, 1 minute, 1 second
    return formatted
        .replace(/\b0 days \b/, "")
        .replace(/\b1 days\b/, "1 day")
        .replace(/\b1 hours\b/, "1 hour")
        .replace(/\b1 minutes\b/, "1 minute")
        .replace(/\b1 seconds\b/, "1 second")
        .replace(/\b0d \b/, "")
}
