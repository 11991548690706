import { Text } from "@chakra-ui/react"
import { TRANSACTION_TEXT_HELPER_DATA_CY_ID } from "@/components/common/blockchain/TransactionHelperText/TransactionHelperTextConstants.ts"

interface Props {
    transactionIsLoading: boolean
    receiptIsLoading: boolean
    errorMessage?: string
}

export const TransactionHelperText: React.FC<Props> = ({
    transactionIsLoading,
    receiptIsLoading,
    errorMessage,
}) => {
    if (errorMessage)
        return (
            <Text
                data-cy={TRANSACTION_TEXT_HELPER_DATA_CY_ID}
                fontSize={"sm"}
                alignSelf="center"
                color="red.500"
                fontWeight={"medium"}>
                {errorMessage}
            </Text>
        )

    if (!transactionIsLoading && !receiptIsLoading)
        return (
            <Text
                data-cy={TRANSACTION_TEXT_HELPER_DATA_CY_ID}
                fontSize={"sm"}
                alignSelf="center"
                fontWeight={"medium"}
                color="gray.500">
                {
                    "You'll be required to confirm the transaction in your wallet."
                }
            </Text>
        )
    if (transactionIsLoading)
        return (
            <Text
                data-cy={TRANSACTION_TEXT_HELPER_DATA_CY_ID}
                fontSize={"sm"}
                alignSelf="center"
                color="orange.500"
                fontWeight={"medium"}>
                {"Please, confirm the transaction in your wallet..."}
            </Text>
        )

    return (
        <Text
            data-cy={TRANSACTION_TEXT_HELPER_DATA_CY_ID}
            fontSize={"sm"}
            alignSelf="center"
            color="orange.500"
            fontWeight={"medium"}>
            {"We are waiting for the transaction to be confirmed..."}
        </Text>
    )
}
