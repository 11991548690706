import { NumberFormat } from "@/models"

export const currencyCodes: NumberFormat[] = [
    {
        currency: "AED",
        name: "Arab Emirates Dirham",
        locale: "ar-AE",
        formatString: "123,456.79",
    },
    {
        currency: "AFN",
        name: "Afghanistan Afghani",
        locale: "ps-AF",
    },
    {
        currency: "ALL",
        name: "Albanian Lek",
        locale: "sq-AL",
    },
    {
        currency: "AMD",
        name: "Armenian Dram",
        locale: "hy-AM",
    },
    {
        currency: "ANG",
        name: "Netherlands Antillean Guilder",
        locale: "nl-CW",
    },
    {
        currency: "AOA",
        name: "Angolan Kwanza",
        locale: "ln-AO",
    },
    {
        currency: "ARS",
        name: "Argentine Peso",
        locale: "es-AR",
    },
    {
        currency: "AUD",
        name: "Australian Dollar",
        locale: "en-AU",
    },
    {
        currency: "AWG",
        name: "Aruban Guilder",
        locale: "nl-AW",
    },
    {
        currency: "AZN",
        name: "Azerbaijan New Manat",
        locale: "az-Cyrl-AZ",
    },
    {
        currency: "BAM",
        name: "Marka",
        locale: "bs-Cyrl-BA",
    },
    {
        currency: "BBD",
        name: "Barbados Dollar",
        locale: "en-BB",
    },
    {
        currency: "BDT",
        name: "Bangladeshi Taka",
        locale: "bn-BD",
    },
    {
        currency: "BGN",
        name: "Bulgarian Lev",
        locale: "bg-BG",
    },
    {
        currency: "BHD",
        name: "Bahraini Dinar",
        locale: "ar-BH",
    },
    {
        currency: "BIF",
        name: "Burundi Franc",
        locale: "rn-BI",
    },
    {
        currency: "BMD",
        name: "Bermudian Dollar",
        locale: "en-BM",
    },
    {
        currency: "BND",
        name: "Brunei Dollar",
        locale: "ms-Latn-BN",
    },
    {
        currency: "BOB",
        name: "Boliviano",
        locale: "es-BO",
    },
    {
        currency: "BRL",
        name: "Brazilian Real",
        locale: "pt-BR",
    },
    {
        currency: "BSD",
        name: "Bahamian Dollar",
        locale: "en-BS",
    },
    {
        currency: "BTN",
        name: "Bhutan Ngultrum",
        locale: "dz-BT",
    },
    {
        currency: "BWP",
        name: "Botswana Pula",
        locale: "en-BW",
    },
    {
        currency: "BYR",
        name: "Belarussian Ruble",
        locale: "ru-BY",
        formatString: "123 456,79",
    },
    {
        currency: "BZD",
        name: "Belize Dollar",
        locale: "en-BZ",
    },
    {
        currency: "CAD",
        name: "Canadian Dollar",
        locale: "fr-CA",
    },
    {
        currency: "CDF",
        name: "Congo/Kinshasa Franc",
        locale: "lu-CD",
    },
    {
        currency: "CHF",
        name: "Swiss Franc",
        locale: "rm-CH",
    },
    {
        currency: "CLP",
        name: "Chilean Peso",
        locale: "es-CL",
    },
    {
        currency: "CNY",
        name: "Yuan Renminbi",
        locale: "ii-CN",
    },
    {
        currency: "COP",
        name: "Colombian Peso",
        locale: "es-CO",
    },
    {
        currency: "CRC",
        name: "Costa Rican Colon",
        locale: "es-CR",
    },
    {
        currency: "CUP",
        name: "Cuban Peso",
        locale: "es-CU",
    },
    {
        currency: "CVE",
        name: "Cape Verde Escudo",
        locale: "pt-CV",
    },
    {
        currency: "CZK",
        name: "Czech Koruna",
        locale: "en-CZ",
    },
    {
        currency: "DJF",
        name: "Djibouti Franc",
        locale: "ar-DJ",
    },
    {
        currency: "DKK",
        name: "Danish Krone",
        locale: "da-DK",
    },
    {
        currency: "DOP",
        name: "Dominican Peso",
        locale: "es-DO",
    },
    {
        currency: "DZD",
        name: "Algerian Dinar",
        locale: "kab-DZ",
    },
    {
        currency: "EGP",
        name: "Egyptian Pound",
        locale: "ar-EG",
    },
    {
        currency: "ERN",
        name: "Eritrean Nakfa",
        locale: "ar-ER",
    },
    {
        currency: "ETB",
        name: "Ethiopian Birr",
        locale: "om-ET",
    },
    {
        currency: "EUR",
        name: "Euro",
        locale: "en-DE",
    },
    {
        currency: "FJD",
        name: "Fiji Dollar",
        locale: "en-FJ",
    },
    {
        currency: "FKP",
        name: "Falkland Islands Pound",
        locale: "en-FK",
    },
    {
        currency: "GBP",
        name: "Pound Sterling",
        locale: "en-GB",
    },
    {
        currency: "GEL",
        name: "Georgian Lari",
        locale: "ka-GE",
    },
    {
        currency: "GHS",
        name: "Ghanaian Cedi",
        locale: "ak-GH",
    },
    {
        currency: "GGP",
        name: "Guernsey Pound",
        locale: "en-GG",
    },
    {
        currency: "GIP",
        name: "Gibraltar Pound",
        locale: "en-GI",
    },
    {
        currency: "GMD",
        name: "Gambian Dalasi",
        locale: "en-GM",
    },
    {
        currency: "GNF",
        name: "Guinea Franc",
        locale: "fr-GN",
    },
    {
        currency: "GTQ",
        name: "Guatemalan Quetzal",
        locale: "es-GT",
    },
    {
        currency: "GYD",
        name: "Guyana Dollar",
        locale: "en-GY",
    },
    {
        currency: "HKD",
        name: "Hong Kong Dollar",
        locale: "zh-Hans-HK",
    },
    {
        currency: "HNL",
        name: "Honduran Lempira",
        locale: "es-HN",
    },
    {
        currency: "HRK",
        name: "Croatian Kuna",
        locale: "hr-HR",
    },
    {
        currency: "HTG",
        name: "Haitian Gourde",
        locale: "fr-HT",
    },
    {
        currency: "HUF",
        name: "Hungarian Forint",
        locale: "en-HU",
    },
    {
        currency: "IDR",
        name: "Indonesian Rupiah",
        locale: "id-ID",
    },
    {
        currency: "ILS",
        name: "Israeli New Shekel",
        locale: "ar-IL",
    },
    {
        currency: "INR",
        name: "Indian Rupee",
        locale: "kok-IN",
    },
    {
        currency: "IQD",
        name: "Iraqi Dinar",
        locale: "ar-IQ",
    },
    {
        currency: "IRR",
        name: "Iranian Rial",
        locale: "fa-IR",
    },
    {
        currency: "ISK",
        name: "Iceland Krona",
        locale: "en-IS",
    },
    {
        currency: "JMD",
        name: "Jamaican Dollar",
        locale: "en-JM",
    },
    {
        currency: "JOD",
        name: "Jordanian Dinar",
        locale: "ar-JO",
    },
    {
        currency: "JPY",
        name: "Japanese Yen",
        locale: "ja-JP",
    },
    {
        currency: "KES",
        name: "Kenyan Shilling",
        locale: "saq-KE",
    },
    {
        currency: "KGS",
        name: "Som",
        locale: "ru-KG",
    },
    {
        currency: "KHR",
        name: "Kampuchean Riel",
        locale: "km-KH",
    },
    {
        currency: "KMF",
        name: "Comoros Franc",
        locale: "ar-KM",
    },
    {
        currency: "KPW",
        name: "North Korean Won",
        locale: "ko-KP",
    },
    {
        currency: "KRW",
        name: "Korean Won",
        locale: "ko-KR",
    },
    {
        currency: "KWD",
        name: "Kuwaiti Dinar",
        locale: "ar-KW",
    },
    {
        currency: "KYD",
        name: "Cayman Islands Dollar",
        locale: "en-KY",
    },
    {
        currency: "KZT",
        name: "Kazakhstan Tenge",
        locale: "kk-Cyrl-KZ",
    },
    {
        currency: "LAK",
        name: "Lao Kip",
        locale: "lo-LA",
    },
    {
        currency: "LBP",
        name: "Lebanese Pound",
        locale: "ar-LB",
    },
    {
        currency: "LKR",
        name: "Sri Lanka Rupee",
        locale: "ta-LK",
    },
    {
        currency: "LRD",
        name: "Liberian Dollar",
        locale: "vai-Latn-LR",
    },
    {
        currency: "LSL",
        name: "Lesotho loti",
        locale: "en-LS",
    },
    {
        currency: "LTL",
        name: "Lithuanian Litas",
        locale: "en-LT",
    },
    {
        currency: "LVL",
        name: "Latvian Lats",
        locale: "en-LV",
    },
    {
        currency: "LYD",
        name: "Libyan Dinar",
        locale: "ar-LY",
    },
    {
        currency: "MAD",
        name: "Moroccan Dirham",
        locale: "ar-MA",
    },
    {
        currency: "MDL",
        name: "Moldovan Leu",
        locale: "ru-MD",
    },
    {
        currency: "MGA",
        name: "Malagasy Ariary",
        locale: "mg-MG",
    },
    {
        currency: "MKD",
        name: "Denar",
        locale: "sq-MK",
    },
    {
        currency: "MMK",
        name: "Myanmar Kyat",
        locale: "my-MM",
    },
    {
        currency: "MNT",
        name: "Mongolian Tugrik",
        locale: "mn-Cyrl-MN",
    },
    {
        currency: "MOP",
        name: "Macau Pataca",
        locale: "zh-Hant-MO",
    },
    {
        currency: "MRO",
        name: "Mauritanian Ouguiya",
        locale: "ar-MR",
    },
    {
        currency: "MUR",
        name: "Mauritius Rupee",
        locale: "en-MU",
    },
    {
        currency: "MWK",
        name: "Malawi Kwacha",
        locale: "en-MW",
    },
    {
        currency: "MXN",
        name: "Mexican Nuevo Peso",
        locale: "es-MX",
    },
    {
        currency: "MYR",
        name: "Malaysian Ringgit",
        locale: "ms-Latn-MY",
    },
    {
        currency: "MZN",
        name: "Mozambique Metical",
        locale: "mgh-MZ",
    },
    {
        currency: "NAD",
        name: "Namibian Dollar",
        locale: "naq-NA",
    },
    {
        currency: "NGN",
        name: "Nigerian Naira",
        locale: "ha-Latn-NG",
    },
    {
        currency: "NIO",
        name: "Nicaraguan Cordoba Oro",
        locale: "es-NI",
    },
    {
        currency: "NOK",
        name: "Norwegian Krone",
        locale: "nn-NO",
    },
    {
        currency: "NPR",
        name: "Nepalese Rupee",
        locale: "ne-NP",
    },
    {
        currency: "NZD",
        name: "New Zealand Dollar",
        locale: "en-NZ",
    },
    {
        currency: "OMR",
        name: "Omani Rial",
        locale: "ar-OM",
    },
    {
        currency: "PAB",
        name: "Panamanian Balboa",
        locale: "es-PA",
    },
    {
        currency: "PEN",
        name: "Peruvian Nuevo Sol",
        locale: "es-PE",
    },
    {
        currency: "PGK",
        name: "Papua New Guinea Kina",
        locale: "en-PG",
    },
    {
        currency: "PHP",
        name: "Philippine Peso",
        locale: "es-PH",
    },
    {
        currency: "PKR",
        name: "Pakistan Rupee",
        locale: "pa-Arab-PK",
    },
    {
        currency: "PLN",
        name: "Polish Zloty",
        locale: "en-PL",
    },
    {
        currency: "PYG",
        name: "Paraguay Guarani",
        locale: "es-PY",
    },
    {
        currency: "QAR",
        name: "Qatari Rial",
        locale: "ar-QA",
    },
    {
        currency: "RON",
        name: "Romanian New Leu",
        locale: "en-RO",
    },
    {
        currency: "RSD",
        name: "Dinar",
        locale: "sr-Latn-RS",
    },
    {
        currency: "RUB",
        name: "Russian Ruble",
        locale: "ru-RU",
        formatString: "123 456,79",
    },
    {
        currency: "RWF",
        name: "Rwanda Franc",
        locale: "rw-RW",
    },
    {
        currency: "SAR",
        name: "Saudi Riyal",
        locale: "ar-SA",
    },
    {
        currency: "SBD",
        name: "Solomon Islands Dollar",
        locale: "en-SB",
    },
    {
        currency: "SCR",
        name: "Seychelles Rupee",
        locale: "en-SC",
    },
    {
        currency: "SDG",
        name: "Sudanese Pound",
        locale: "ar-SD",
    },
    {
        currency: "SEK",
        name: "Swedish Krona",
        locale: "sv-SE",
    },
    {
        currency: "SGD",
        name: "Singapore Dollar",
        locale: "ms-Latn-SG",
        formatString: "123,456.79",
        img: "/images/currency/ms-Latn-SG.webp",
    },
    {
        currency: "SGD",
        name: "Singapore Dollar",
        locale: "en-SG",
        formatString: "123,456.79",
        img: "/images/currency/ms-Latn-SG.webp",
    },
    {
        currency: "SHP",
        name: "Saint Helena Pound",
        locale: "en-SH",
    },
    {
        currency: "SLL",
        name: "Sierra Leone Leone",
        locale: "en-SL",
    },
    {
        currency: "SOS",
        name: "Somali Shilling",
        locale: "ar-SO",
    },
    {
        currency: "SRD",
        name: "Surinam Dollar",
        locale: "nl-SR",
    },
    {
        currency: "STD",
        name: "Dobra",
        locale: "pt-ST",
    },
    {
        currency: "SYP",
        name: "Syrian Pound",
        locale: "ar-SY",
    },
    {
        currency: "SZL",
        name: "Swaziland Lilangeni",
        locale: "en-SZ",
    },
    {
        currency: "THB",
        name: "Thai Baht",
        locale: "th-TH",
    },
    {
        currency: "TJS",
        name: "Tajik Somoni",
        locale: "tg-Cyrl-TJ",
    },
    {
        currency: "TMT",
        name: "Manat",
        locale: "tk-Latn-TM",
    },
    {
        currency: "TND",
        name: "Tunisian Dollar",
        locale: "ar-TN",
    },
    {
        currency: "TOP",
        name: "Tongan Pa'anga",
        locale: "to-TO",
    },
    {
        currency: "TRY",
        name: "Turkish Lira",
        locale: "tr-TR",
    },
    {
        currency: "TTD",
        name: "Trinidad and Tobago Dollar",
        locale: "en-TT",
    },
    {
        currency: "TWD",
        name: "Taiwan Dollar",
        locale: "zh-Hant-TW",
    },
    {
        currency: "TZS",
        name: "Tanzanian Shilling",
        locale: "sw-TZ",
    },
    {
        currency: "UAH",
        name: "Ukraine Hryvnia",
        locale: "ru-UA",
    },
    {
        currency: "UGX",
        name: "Uganda Shilling",
        locale: "teo-UG",
    },
    {
        currency: "USD",
        name: "US Dollar",
        locale: "en-US-POSIX",
    },
    {
        currency: "USD",
        name: "US Dollar",
        locale: "en-US",
        formatString: "123,456.79",
    },
    {
        currency: "UYU",
        name: "Uruguayan Peso",
        locale: "es-UY",
    },
    {
        currency: "UZS",
        name: "Uzbekistan Sum",
        locale: "uz-Cyrl-UZ",
    },
    {
        currency: "VEF",
        name: "Venezuelan Bolivar",
        locale: "es-VE",
    },
    {
        currency: "VND",
        name: "Vietnamese Dong",
        locale: "vi-VN",
    },
    {
        currency: "VUV",
        name: "Vanuatu Vatu",
        locale: "en-VU",
    },
    {
        currency: "WST",
        name: "Samoan Tala",
        locale: "en-WS",
    },
    {
        currency: "YER",
        name: "Yemeni Rial",
        locale: "ar-YE",
    },
    {
        currency: "ZAR",
        name: "South African Rand",
        locale: "en-LS",
    },
    {
        currency: "ZMW",
        name: "Zambian Kwacha",
        locale: "af-ZA",
    },
]
