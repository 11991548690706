import { SignClient as Client } from "@walletconnect/sign-client/dist/types/client"
import { SessionTypes } from "@walletconnect/types"
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { SignClient } from "@walletconnect/sign-client"
import { Network } from "@/models"
import { getSdkError } from "@walletconnect/utils"
import { Certificate } from "thor-devkit"
import {
    DEFAULT_APP_METADATA,
    DEFAULT_LOGGER,
    DEFAULT_METHODS,
    DEFAULT_PROJECT_ID,
    SUPPORTED_CHAINS,
} from "@/constants"
import { WalletConnectModal } from "@walletconnect/modal"
import { EngineTypes } from "@walletconnect/types/dist/types/sign-client/engine"
import { getWalletConnectChainId, isMobile } from "@/utils"
import { logger } from "@/logger"
import { useAccountStore } from "@/store"

interface IContext {
    client: Client | undefined
    session: SessionTypes.Struct | undefined
    connect: (
        onSuccess: (session: SessionTypes.Struct) => void,
        onError?: (err: unknown) => void,
    ) => Promise<void>
    disconnect: () => Promise<void>
    isInitializing: boolean
    identifyUser: (
        network: Network,
        session: SessionTypes.Struct,
    ) => Promise<Certificate>
    signMessage: (
        network: Network,
        session: SessionTypes.Struct,
        message: Connex.Vendor.CertMessage,
    ) => Promise<Certificate>
    signTx: (
        network: Network,
        message: Connex.Vendor.TxMessage,
        options?: Connex.Driver.TxOptions,
    ) => Promise<Connex.Vendor.TxResponse>
}

/**
 * Context
 */
export const WalletConnectContext = createContext<IContext>({} as IContext)

/**
 * Web3Modal Config
 */

const web3Modal = new WalletConnectModal({
    projectId: DEFAULT_PROJECT_ID,
    explorerRecommendedWalletIds: "NONE",
    mobileWallets: [
        {
            name: "VeWorld",
            id: "veworld-mobile",
            links: {
                native: "wc://",
                universal: "https://veworld.net",
            },
        },
    ],
    themeVariables: {
        "--wcm-z-index": "99999999",
    },
    walletImages: {
        "veworld-mobile": "https://i.ibb.co/8BFNWM9/veWorld.png",
    },
})

interface IWalletConnectProvider {
    children: React.ReactNode
}

export const WalletConnectProvider = ({ children }: IWalletConnectProvider) => {
    const [client, setClient] = useState<Client>()
    const [session, setSession] = useState<SessionTypes.Struct>()
    const { clear: clearConnectedAccount } = useAccountStore()

    const [isInitializing, setIsInitializing] = useState(false)

    const reset = () => {
        logger.info("Resetting WalletConnect state")
        setSession(undefined)

        clearConnectedAccount()
    }

    const connect = useCallback(
        async (
            onSuccess: (session: SessionTypes.Struct) => void,
            onError?: (err: unknown) => void,
        ) => {
            if (!client) throw new Error("WalletConnect is not initialized")

            try {
                const requiredNamespaces: EngineTypes.ConnectParams["requiredNamespaces"] =
                    {
                        vechain: {
                            methods: Object.values(DEFAULT_METHODS),
                            chains: SUPPORTED_CHAINS,
                            events: [],
                        },
                    }

                const { uri, approval } = await client.connect({
                    requiredNamespaces,
                })

                if (uri) {
                    // Create a flat array of all requested chains across namespaces.
                    const chains = Object.values(requiredNamespaces)
                        .map(namespace => namespace.chains)
                        .flat() as string[]

                    await web3Modal.openModal({ uri, chains })
                }

                // Detect if user closed modal without connecting
                const session = await new Promise<SessionTypes.Struct>(
                    (resolve, reject) => {
                        // use self-invoking function to handle async/await inside promise
                        ;(async () => {
                            web3Modal.subscribeModal(
                                (ev: { open: boolean }) => {
                                    logger.info(
                                        ev.open
                                            ? "Modal opened"
                                            : "Modal closed",
                                    )
                                    if (!ev.open) {
                                        reject(new Error("User closed modal"))
                                    }
                                },
                            )

                            return approval()
                                .then(newSession => {
                                    resolve(newSession)
                                })
                                .catch(reject)
                        })()
                    },
                )
                logger.debug("Established session:", session)
                setSession(session)
                onSuccess(session)
                web3Modal.closeModal()
            } catch (e) {
                web3Modal.closeModal()
                onError?.(e)

                throw e
            }
        },
        [client],
    )

    const identifyUser = useCallback(
        async (network: Network, session: SessionTypes.Struct) => {
            if (!client) throw new Error("WalletConnect is not initialized")
            if (!session) throw new Error("Session is not connected")

            const message: Connex.Vendor.CertMessage = {
                purpose: "identification",
                payload: {
                    type: "text",
                    content: "Sign a certificate to prove your identity",
                },
            }

            try {
                return await signMessage(network, session, message)
            } catch (e) {
                logger.error("SignClient.identifyUser failed:", e)

                // Disconnect from session if user rejects identify signature request
                await client.disconnect({
                    topic: session.topic,
                    reason: getSdkError("USER_DISCONNECTED"),
                })

                throw e
            }
        },
        [client, session],
    )

    const signMessage = useCallback(
        async (
            network: Network,
            session: SessionTypes.Struct,
            message: Connex.Vendor.CertMessage,
        ) => {
            if (!client) throw new Error("WalletConnect is not initialized")

            if (!session) throw new Error("Session is not connected")

            try {
                const options: Connex.Driver.CertOptions = {}

                const resPromise: Promise<Connex.Vendor.CertResponse> =
                    client.request({
                        topic: session.topic,
                        chainId: getWalletConnectChainId(network),
                        request: {
                            method: DEFAULT_METHODS.SIGN_CERTIFICATE,
                            params: [{ message, options }],
                        },
                    })

                if (isMobile() && !document.hidden) window.open("wc://")

                const result = await resPromise

                const cert: Certificate = {
                    purpose: message.purpose,
                    payload: message.payload,
                    domain: result.annex.domain,
                    timestamp: result.annex.timestamp,
                    signer: result.annex.signer,
                    signature: result.signature,
                }

                logger.debug("Signed message", cert)
                Certificate.verify(cert)
                logger.info("Message signature verified")

                return cert
            } catch (e) {
                logger.error("SignClient.signMessage failed:", e)

                throw e
            }
        },
        [client, session],
    )

    const signTx = async (
        network: Network,
        message: Connex.Vendor.TxMessage,
        options?: Connex.Driver.TxOptions,
    ): Promise<Connex.Vendor.TxResponse> => {
        if (!client) throw new Error("WalletConnect is not initialized")

        if (!session) throw new Error("Session is not connected")

        const resPromise: Promise<Connex.Vendor.TxResponse> = client.request({
            topic: session.topic,
            chainId: getWalletConnectChainId(network),
            request: {
                method: DEFAULT_METHODS.REQUEST_TRANSACTION,
                params: [{ message, options }],
            },
        })
        if (isMobile() && !document.hidden) window.open("wc://")

        const result = await resPromise
        return result
    }

    const disconnect = useCallback(async () => {
        if (typeof client === "undefined") {
            throw new Error("WalletConnect client is not initialized")
        }
        if (typeof session === "undefined") {
            throw new Error("Session is not connected")
        }

        try {
            await client.disconnect({
                topic: session.topic,
                reason: getSdkError("USER_DISCONNECTED"),
            })
        } catch (e) {
            logger.error("SignClient.disconnect failed:", e)
        } finally {
            // Reset app state after disconnect.
            reset()
        }
    }, [client, session])

    const subscribeToEvents = useCallback(async (_client: Client) => {
        if (typeof _client === "undefined") {
            throw new Error("WalletConnect is not initialized")
        }

        _client.on("session_ping", args => {
            logger.debug("EVENT", "session_ping", args)
        })

        _client.on("session_event", args => {
            logger.debug("EVENT", "session_event", args)
        })

        _client.on("session_update", ({ topic, params }) => {
            logger.debug("EVENT", "session_update", { topic, params })
            const { namespaces } = params
            const _session = _client.session.get(topic)
            const updatedSession = { ..._session, namespaces }
            setSession(updatedSession)
        })

        _client.on("session_delete", () => {
            logger.debug("EVENT", "session_delete")
            reset()
        })
    }, [])

    const restoreExistingSession = useCallback(
        async (_client: Client) => {
            if (typeof _client === "undefined") {
                throw new Error("WalletConnect is not initialized")
            }

            if (typeof session !== "undefined") return

            // populates (the last) existing session to state
            if (_client.session.length) {
                const lastKeyIndex = _client.session.keys.length - 1
                const _session = _client.session.get(
                    _client.session.keys[lastKeyIndex],
                )
                logger.debug("RESTORED SESSION:", _session)
                setSession(_session)

                return _session
            }
        },
        [session],
    )

    const createClient = useCallback(async () => {
        try {
            setIsInitializing(true)

            const _client = await SignClient.init({
                logger: DEFAULT_LOGGER,
                projectId: DEFAULT_PROJECT_ID,
                metadata: DEFAULT_APP_METADATA,
            })

            setClient(_client)

            await restoreExistingSession(_client)
            await subscribeToEvents(_client)
        } catch (err) {
            logger.error("Failed to initialize WalletConnect client:", err)
            throw err
        } finally {
            setIsInitializing(false)
        }
    }, [restoreExistingSession, subscribeToEvents])

    useEffect(() => {
        if (!client) {
            createClient()
        }
    }, [createClient, client])

    const value = useMemo(
        () => ({
            isInitializing,
            client,
            session,
            connect,
            disconnect,
            identifyUser,
            signMessage,
            signTx,
        }),
        [
            isInitializing,
            client,
            session,
            connect,
            disconnect,
            identifyUser,
            signMessage,
            signTx,
        ],
    )

    return (
        <WalletConnectContext.Provider value={value}>
            {children}
        </WalletConnectContext.Provider>
    )
}

// eslint-disable-next-line react-refresh/only-export-components
export function useWalletConnect() {
    const context = useContext(WalletConnectContext)
    if (context === undefined) {
        throw new Error("useWalletConnect must be used within a CountProvider")
    }
    return context
}
