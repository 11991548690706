export enum AnalyticsEventsEnum {
    WALLET_CONNECTED = "WALLET_CONNECTED",
    WALLET_DISCONNECTED = "WALLET_DISCONNECTED",
    SUCCESSFUL_CLAIM = "SUCCESSFUL_CLAIM",
    CLAIM_CLICKED = "CLAIM_CLICKED",
    GO_TO_MARKEPLACE = "GO_TO_MARKEPLACE",
    ABOUT_REWARDS_CLICKED = "ABOUT_REWARDS_CLICKED",
    API_ERROR = "API_ERROR",
    API_CALL = "API_CALL",

    OPEN_MANAGE_NODE = "OPEN_MANAGE_NODE",
    GO_TO_INSTANT_TRANSFER = "GO_TO_INSTANT_TRANSFER",
    GO_TO_DIRECT_SALE = "GO_TO_DIRECT_SALE",
    GO_TO_AUCTION_SALE = "GO_TO_AUCTION_SALE",
    GO_TO_PENDING_PROPOSALS = "GO_TO_PENDING_PROPOSALS",

    APPLY_FOR_ECONOMIC_NODE_CLICKED = "APPLY_FOR_ECONOMIC_NODE_CLICKED",
    APPLY_FOR_ECONOMIC_NODE_CONFIRMED = "APPLY_FOR_ECONOMIC_NODE_CONFIRMED",
    APPLY_FOR_ECONOMIC_NODE_SUCCESSFUL = "APPLY_FOR_ECONOMIC_NODE_SUCCESSFUL",
    APPLY_FOR_ECONOMIC_NODE_CANCEL_CLICKED = "APPLY_FOR_ECONOMIC_NODE_CANCEL_CLICKED",
    APPLY_FOR_ECONOMIC_NODE_CANCEL_SUCCESSFUL = "APPLY_FOR_ECONOMIC_NODE_CANCEL_SUCCESSFUL",

    APPLY_UPGRADE_CLICKED = "APPLY_UPGRADE_CLICKED",
    APPLY_UPGRADE_CONFIRMED = "APPLY_UPGRADE_CONFIRMED",
    APPLY_UPGRADE_SUCCESSFUL = "APPLY_UPGRADE_SUCCESSFUL",
    APPLY_UPGRADE_CANCEL_CLICKED = "APPLY_UPGRADE_CANCEL_CLICKED",
    APPLY_UPGRADE_CANCEL_SUCCESSFUL = "APPLY_UPGRADE_CANCEL_SUCCESSFUL",

    TRANSFER_NODE_CLICKED = "TRANSFER_NODE_CLICKED",
    TRANSFER_NODE_CONFIRMED = "TRANSFER_NODE_CONFIRMED",
    TRANSFER_NODE_SUCCESSFUL = "TRANSFER_NODE_SUCCESSFUL",

    CREATE_SALE_CLICKED = "CREATE_SALE_CLICKED",
    CREATE_SALE_SUCCESSFUL = "CREATE_SALE_SUCCESSFUL",

    CANCEL_SALE_CLICKED = "CANCEL_SALE_CLICKED",
    CANCEL_SALE_SUCCESSFUL = "CANCEL_AUCTION_SALE_SUCCESSFUL",

    BID_CLICKED = "BID_CLICKED",
    BID_SUCCESSFUL = "BID_SUCCESSFUL",
}

export enum ApiCallStateEnum {
    SUCCESS = "SUCCESS",
    UNAUTHORISED = "UNAUTHORISED",
    ERROR = "ERROR",
}

export enum ApiErrorClassification {
    RESPONDED = "RESPONSED",
    NO_RESPONSE = "NO_RESPONSE",
    UNKNOWN = "UNKNOWN",
}
