import ReactDOM from "react-dom/client"
import { ChakraProvider } from "@chakra-ui/react"
import { TranslationProvider, WalletConnectProvider } from "@/contexts"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { router } from "@/router"
import { RouterProvider } from "react-router-dom"
import { theme } from "./theme"
import { AnalyticsUtils, ViteUtils } from "./utils"
import { logger } from "@/logger"
import { ErrorBoundary, FreshDeskWidget } from "./components"
import * as Sentry from "@sentry/react"
import "./styles/freshdesk-iframe.css"

// required to use connex
window.global ||= window

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            staleTime: 30000,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            cacheTime: 60000,
        },
    },
})

logger.log("Environment is DEV", import.meta.env.DEV)
logger.log("Environment is PROD", import.meta.env.PROD)
logger.log("Mode is ", import.meta.env.MODE)
logger.log("Contract is ", import.meta.env.VITE_VNT_CONTRACT)
logger.log("Solo node url is ", import.meta.env.VITE_THOR_SOLO_URL)
logger.log("Backend api url is ", import.meta.env.VITE_API_BASE_URL)
logger.log("RipeSeconds ", import.meta.env.VITE_NODES_RIPE_SECONDS)
logger.log("AppVersion ", __APP_VERSION__)

AnalyticsUtils.initialise()
logger.info("Analytics initialised")

Sentry.init({
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_PROJECT_URL,
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
    replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
})
logger.info("Sentry initialised")

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <TranslationProvider>
        <ChakraProvider theme={theme}>
            <ErrorBoundary>
                <WalletConnectProvider>
                    <QueryClientProvider client={queryClient}>
                        {ViteUtils.isMode("production", "demo") && (
                            <FreshDeskWidget widgetId={103000006923} />
                        )}
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </WalletConnectProvider>
            </ErrorBoundary>
        </ChakraProvider>
    </TranslationProvider>,
)
