import BigNumber from "bignumber.js"
import { logger } from "@/logger"

interface NumberFormatOptions {
    style?: string | undefined
    currency?: string | undefined
    minimumFractionDigits?: number | undefined
    maximumFractionDigits?: number | undefined
}

export const humanAddress = (
    address: string,
    lengthBefore = 2,
    lengthAfter = 4,
) => {
    const before = address.substring(0, lengthBefore)
    const after = address.substring(address.length - lengthAfter)
    return `${before}…${after}`
}

/**
 * Scale the number up by the specified number of decimal places
 * @param val - the value to scale up (a number or string representation of a number)
 * @param scaleDecimal - the number of decimals to scale up by
 * @param roundDecimal - the number of decimals to round the result to
 * @param roundingStrategy - what strategy to use when rounding. Based on the strategies defined in `bignumber.js`. Default strategy is ROUND_HALF_UP
 * @returns the scaled up result as a string
 */
export const scaleNumberUp = (
    val: BigNumber.Value,
    scaleDecimal: number,
    roundDecimal = 0,
    roundingStrategy = BigNumber.ROUND_HALF_UP,
): string => {
    try {
        if (scaleDecimal === 0) return new BigNumber(val).toFixed()
        if (scaleDecimal < 0)
            throw Error("Decimal value must be greater than or equal to 0")
        const valBn = new BigNumber(val)
        if (valBn.isNaN()) throw Error("The value provided is NaN.")

        const amount = valBn.times(`1${"0".repeat(scaleDecimal)}`)

        if (scaleDecimal === roundDecimal) return amount.toFixed()

        return amount.toFixed(roundDecimal, roundingStrategy)
    } catch (e) {
        logger.error(e)
        throw new Error(`Failed to scale number up (${val})`)
    }
}

/**
 * Scale the number down by the specified number of decimal places
 * @param val - the value to scale down (a number or string representation of a number)
 * @param scaleDecimal - the number of decimals to scale down by
 * @param roundDecimal - the number of decimals to round the result to
 * @param roundingStrategy - what strategy to use when rounding. Based on the strategies defined in `bignumber.js`. Default strategy is ROUND_HALF_UP
 * @returns the scaled up result as a string
 */
export const scaleNumberDown = (
    val: BigNumber.Value,
    scaleDecimal: number,
    roundDecimal = 0,
    roundingStrategy = BigNumber.ROUND_HALF_UP,
): string => {
    try {
        if (scaleDecimal === 0) return new BigNumber(val).toFixed()
        if (scaleDecimal < 0)
            throw Error("Decimal value must be greater than or equal to 0")

        const valBn = new BigNumber(val)
        if (valBn.isNaN()) throw Error("The value provided is NaN.")

        const amount = valBn.dividedBy(`1${"0".repeat(scaleDecimal)}`)

        if (scaleDecimal === roundDecimal) return amount.toFixed()

        return amount.toFixed(roundDecimal, roundingStrategy)
    } catch (e) {
        logger.error(e)
        throw new Error(`Failed to scale number down (${val})`)
    }
}

/**
 * Format the number human friendly
 * @param formattedValue - value in string or number
 * @param originalValue - value in string or number to determine if the original value is 0
 * @param isFiat - fiat flag to fix amount of decimals
 * @param locale - locale for number formatting
 * @returns the formatted number
 */
export const humanNumber = (
    formattedValue: BigNumber.Value,
    originalValue?: BigNumber.Value,
    isFiat = false,
    locale: string | undefined = "en",
) => {
    const fractionDigits =
        Number.parseFloat(formattedValue.toString()) % 1 === 0 ? 0 : 2
    const options: NumberFormatOptions = {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
    }
    if (isFiat) {
        options.maximumFractionDigits = fractionDigits
    }

    let value = new Intl.NumberFormat(locale, options).format(
        Number.parseFloat(formattedValue.toString()),
    )

    //If the original number got scaled down to 0
    if (!isZero(originalValue) && isZero(value)) {
        value = "< 0.01"
    }

    return value
}

export const isZero = (value?: BigNumber.Value) => {
    if (!value && value !== 0) return false
    return new BigNumber(value).isZero()
}

/**
 * Convert token balance to fiat balance
 * @param balance - raw token balance
 * @param rate - exchange rate
 * @param decimals - the number of decimals for token
 * @returns the formatted time
 */
export const convertToFiatBalance = (
    balance: string,
    rate: number,
    decimals: number,
    roundDecimals = 2,
) => {
    const fiatBalance = new BigNumber(balance).multipliedBy(rate)
    return scaleNumberDown(fiatBalance, decimals, roundDecimals)
}
