import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    HStack,
    Heading,
    Icon,
    Text,
} from "@chakra-ui/react"
import React, { useMemo } from "react"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"
import { RewardsLogo } from ".."
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import * as Sentry from "@sentry/react"
import { useAccountStore } from "@/store"

type ErrorBoundaryContentProps = {
    error: Error
    componentStack: string
    eventId: string
    resetError: () => void
}
export const ErrorBoundaryContent: React.FC<ErrorBoundaryContentProps> = ({
    error,
    resetError,
}) => {
    const handleReloadWindow = () => {
        // resetErrorBoundary()
        window.location.reload()
    }

    return (
        <Container maxW="xl" w="full" h="100vh" display={"flex"}>
            <Card flex={1} mx="auto" my="auto">
                <CardHeader flexDir={"column"} gap={0.5}>
                    <RewardsLogo w={150} mx="auto" />
                    <HStack mt={4} gap={2} align={"center"}>
                        <Icon as={ExclamationCircleIcon} w={8} h={8} />
                        <Box>
                            <Heading size={"md"}>{"Ooops!"}</Heading>
                            <Heading size={"sm"} color={"gray.500"}>
                                {"Something went wrong."}
                            </Heading>
                        </Box>
                    </HStack>
                </CardHeader>
                <CardBody>
                    <Text color="red.500" fontSize={"md"} fontWeight={"medium"}>
                        {error?.message ||
                            "An unknown error occurred. Please try again later."}
                    </Text>
                </CardBody>
                <CardFooter flexDir={"column"} gap={2}>
                    <Button onClick={handleReloadWindow}>
                        {"Reload page"}
                    </Button>
                    <Button
                        variant={"link"}
                        fontSize={"sm"}
                        onClick={resetError}>
                        {"Not working? Reload app"}
                    </Button>
                </CardFooter>
            </Card>
        </Container>
    )
}

export const RouteErrorBoundary: React.FC = () => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    const routeError = useRouteError()

    const parseRouteError = useMemo(() => {
        if (isRouteErrorResponse(routeError))
            // error is type `ErrorResponse`
            return routeError.data?.message || routeError.statusText
        if (routeError instanceof Error) return routeError.message
        if (typeof routeError === "string") return routeError
        return "Unknown error"
    }, [routeError])

    throw new Error(parseRouteError)
}

type Props = {
    children: React.ReactNode
}
export const ErrorBoundary: React.FC<Props> = ({ children, ...props }) => {
    const { clear } = useAccountStore()
    return (
        <Sentry.ErrorBoundary
            fallback={props => <ErrorBoundaryContent {...props} />}
            onReset={clear}
            {...props}>
            {children}
        </Sentry.ErrorBoundary>
    )
}
