import { TokenImageBox } from "@/components/NodeDetailsCard/TokenImageBox"
import { NodeParameters, NodeStrengthLevel, NodeVotingTier } from "@/constants"
import { HStack, Text, Icon, Tag, VStack } from "@chakra-ui/react"
import { ReceiptPercentIcon } from "@heroicons/react/20/solid"
import {
    FingerPrintIcon,
    ScaleIcon,
    TrophyIcon,
} from "@heroicons/react/24/solid"

type Props = {
    level: string
    nodeId?: string
    fontWeight?: string
    fontSize?: string
    iconBoxSize?: number
}

export const NodeParametersInfo = ({
    level,
    fontWeight = "bold",
    fontSize = "sm",
    nodeId,
    iconBoxSize = 5,
}: Props) => {
    const params = NodeParameters[level]
    const votingTier = NodeVotingTier[level]

    return (
        <HStack
            align={["center"]}
            w={"full"}
            justify={["center", "flex-start"]}
            spacing={4}
            data-cy="node-parameters-info"
            display={["block", "flex"]}>
            <VStack align={["center", "flex-start"]} w={["full", "50%"]}>
                <TokenImageBox
                    level={level}
                    variant={"default"}
                    wrapperProps={{
                        width: "full",
                    }}
                    renderTokenName={false}
                />
            </VStack>

            <VStack
                align={"flex-start"}
                spacing={[3, 4]}
                w={"full"}
                mt={[8, 0]}>
                <HStack justify={"space-between"} w="full">
                    <HStack spacing={2}>
                        <Icon as={TrophyIcon} boxSize={iconBoxSize} />
                        <Text fontSize={fontSize} fontWeight={fontWeight}>
                            {"Node name"}
                        </Text>
                    </HStack>
                    <Tag
                        colorScheme="gray"
                        size={"md"}
                        data-cy="node-parameters-info__name">
                        {NodeStrengthLevel[level]}
                    </Tag>
                </HStack>

                {nodeId && (
                    <HStack justify={"space-between"} w="full">
                        <HStack spacing={2}>
                            <Icon as={FingerPrintIcon} boxSize={6} />
                            <Text fontSize={fontSize} fontWeight={fontWeight}>
                                {"Node ID"}
                            </Text>
                        </HStack>
                        <Tag
                            colorScheme="gray"
                            size={"md"}
                            data-cy="node-parameters-info__node_id">
                            {`# ${nodeId}`}
                        </Tag>
                    </HStack>
                )}

                <HStack justify={"space-between"} w="full">
                    <HStack spacing={2}>
                        <Icon as={ReceiptPercentIcon} boxSize={iconBoxSize} />
                        <Text fontSize={fontSize} fontWeight={fontWeight}>
                            {"Reward ratio"}
                        </Text>
                    </HStack>
                    <Tag
                        colorScheme="gray"
                        size={"md"}
                        data-cy="node-parameters-info__rewardRatio">
                        {params.rewardRatioX} %
                    </Tag>
                </HStack>

                <HStack justify={"space-between"} w="full">
                    <HStack spacing={2}>
                        <Icon as={ScaleIcon} boxSize={iconBoxSize} />
                        <Text fontSize={fontSize} fontWeight={fontWeight}>
                            {"Voting tier"}
                        </Text>
                    </HStack>
                    <Tag
                        colorScheme="gray"
                        size={"md"}
                        data-cy="node-parameters-info__votingTier">
                        {`${votingTier.tier} ${votingTier.tierType}`}
                    </Tag>
                </HStack>
            </VStack>
        </HStack>
    )
}
