import { Image, ImageProps, useColorModeValue } from "@chakra-ui/react"
import React from "react"

export const RewardsLogo: React.FC<ImageProps> = ({ ...props }) => {
    const lightModeUrl = "/images/logo/rewards.png"
    const darkModeUrl = "/images/logo/rewards_white.png"
    const logoUrl = useColorModeValue(lightModeUrl, darkModeUrl)
    return (
        <Image
            objectFit={"cover"}
            height={"full"}
            alt="Rewards logo"
            src={logoUrl}
            {...props}
        />
    )
}
