import { useQuery } from "@tanstack/react-query"
import { getRewardsHistory, getRewardInfo, getExchangeRate } from "./endpoints"
import { ConnexService, TokenAuctionService } from "@/services"
import { NodeParameters } from "@/constants"
import { AddressUtils } from "@/utils"
import { GetEventsProps } from "@/services/TokenAuctionService/TokenAuctionService"
import ClockAuctionService from "@/services/ClockAuctionService"
import { CompleteTokenDetails } from "@/models"

const coingeckoVetKey = "vechain"
const coingeckoVthoKey = "vethor-token"

const getAccountBalanceQueryKey = (address?: string) => [
    "ACCOUNT_BALANCE",
    address,
]

export const useAccountBalance = (address?: string) => {
    return useQuery(
        getAccountBalanceQueryKey(address),
        () => ConnexService.getAccount(address),
        { enabled: !!address && AddressUtils.isValid(address) },
    )
}

export const getRewardInfoQueryKey = (address?: string) => [
    "REWARD_INFO",
    address,
]

/**
 *  Get the reward info of an address
 * @param address the address to get the reward info
 * @returns  the reward info
 */
export const useRewardInfo = (address?: string) => {
    return useQuery(
        getRewardInfoQueryKey(address),
        () => getRewardInfo(address),
        { enabled: !!address && AddressUtils.isValid(address) },
    )
}

export const getRewardsHistoryQueryKey = (
    address: string,
    page?: number,
    size?: number,
) => [
    "REWARDS_HISTORY",
    address,
    ...(page ? [page] : []),
    ...(size ? [size] : []),
]

/**
 *  Get the rewards history of an address
 * @param address the address to get the rewards history
 * @param page the page to get
 * @param size the size of the page
 * @returns the rewards history
 */
export const useRewardsHistory = (
    address: string,
    page = 1,
    size = 10,
    keepPreviousData = false,
) => {
    return useQuery(
        getRewardsHistoryQueryKey(address, page, size),
        () => getRewardsHistory(address, page, size),
        {
            enabled: !!address && AddressUtils.isValid(address),
            keepPreviousData,
        },
    )
}

const getExchangeRateQueryKey = (id: string, vs_currency: string) => [
    "EXCHANGE_RATE",
    id,
    vs_currency,
]
/**
 *  Get the exchange rate of a coin
 * @param id  the id of the coin
 * @param vs_currencies  the currencies to compare
 * @returns  the exchange rate
 */
export const useExchangeRate = (id: string, vs_currency: string) => {
    return useQuery(getExchangeRateQueryKey(id, vs_currency), () =>
        getExchangeRate(id, vs_currency),
    )
}

/**
 *  Get the exchange rate of VET
 * @param vs_currencies  the currencies to compare
 * @returns  the exchange rate
 */
export const useVetExchangeRate = (vs_currency: string) => {
    return useQuery(getExchangeRateQueryKey(coingeckoVetKey, vs_currency), () =>
        getExchangeRate(coingeckoVetKey, vs_currency),
    )
}

/**
 *  Get the exchange rate of VTHO
 * @param vs_currencies  the currencies to compare
 * @returns  the exchange rate
 */
export const useVthoExchangeRate = (vs_currency: string) => {
    return useQuery(
        getExchangeRateQueryKey(coingeckoVthoKey, vs_currency),
        () => getExchangeRate(coingeckoVthoKey, vs_currency),
    )
}

// Contract API

export const useTotalSupply = () => {
    return useQuery(["TOTAL_SUPPLY"], () => TokenAuctionService.totalSupply())
}

export const useIsOperator = (address: string) => {
    return useQuery(["IS_OPERATOR", address], () =>
        TokenAuctionService.isOperator(address),
    )
}

export const useXTokenCount = () => {
    return useQuery(["X_TOKEN_COUNT"], () => TokenAuctionService.xTokenCount())
}
export const useNormalTokenCount = () => {
    return useQuery(["NORMAL_TOKEN_COUNT"], () =>
        TokenAuctionService.normalTokenCount(),
    )
}
export const getLatestAuctionsQueryKey = () => ["LATEST_AUCTIONS"]

export const useLatestAuctions = () => {
    return useQuery(getLatestAuctionsQueryKey(), () =>
        TokenAuctionService.getActiveAuctions(),
    )
}

export const useBalanceOf = (address: string) => {
    return useQuery(["BALANCE_OF", address], () =>
        TokenAuctionService.balanceOf(address),
    )
}

export const getTokenForAddressQueryKey = (address?: string) => [
    "OWNER_TO_ID",
    address,
]

export const useGetTokenForAddress = (address?: string) => {
    return useQuery(
        getTokenForAddressQueryKey(address),
        () => TokenAuctionService.ownerToId(address),
        { enabled: !!address && AddressUtils.isValid(address) },
    )
}

export const getTokenMetadataQueryKey = (tokenId?: string) => [
    "TOKEN_METADATA",
    tokenId,
]

export const useGetTokenMetadata = (tokenId?: string) => {
    return useQuery(
        getTokenMetadataQueryKey(tokenId),
        () => TokenAuctionService.getMetadata(tokenId),
        { enabled: !!tokenId },
    )
}

export const getTokenParamsQueryKey = (nodeLevel?: string) => [
    "TOKEN_PARAMS",
    nodeLevel,
]

export const useGetTokenParams = (nodeLevel?: string) => {
    return useQuery(
        getTokenParamsQueryKey(nodeLevel),
        // () => TokenAuctionService.getTokenParams(nodeLevel),
        () => (nodeLevel ? NodeParameters[nodeLevel] : undefined),
        { enabled: !!nodeLevel },
    )
}

export const getTxReceipt = (txId?: string) => ["TX_RECEIPT", txId]

/**
 *  Get the tx receipt of a tx id with a block timeout to wait for the receipt
 * @param txId  the tx id to get the receipt
 * @param blocksTimeout  the blocks to wait for the receipt
 * @returns  the tx receipt
 */
export const useGetTxReceipt = (txId?: string, blocksTimeout?: number) => {
    return useQuery(
        getTxReceipt(txId),
        () => ConnexService.pollForReceipt(txId, blocksTimeout),
        { enabled: !!txId, staleTime: Infinity },
    )
}

export const getApplyUpgradeEventsKey = (tokenId?: string) => [
    "APPLY_UPGRADE_EVENTS",
    tokenId,
]

/**
 *  Get the apply upgrade events of a token id (optional for better DX with react-query)
 * @param params  the params to get the apply upgrade events
 * @rejects in case of VM error
 * @returns the apply upgrade events
 */
export const useGetApplyUpgradeEvents = (params: GetEventsProps) => {
    return useQuery(
        getApplyUpgradeEventsKey(params.tokenId),
        () => TokenAuctionService.getApplyUpgradeEvents(params),
        { enabled: !!params.tokenId },
    )
}

export const getLastApplyUpgradeEventKey = (tokenId?: string) => [
    "LAST_APPLY_UPGRADE_EVENT",
    tokenId,
]
/**
 * Get the last apply upgrade event of a token id (optional for better DX with react-query)
 * @param tokenId  the token id to get the last apply upgrade event
 * @returns  the last apply upgrade event
 */
export const useLastApplyUpgradeEvent = (tokenId?: string) => {
    return useQuery(
        getLastApplyUpgradeEventKey(tokenId),
        () =>
            TokenAuctionService.getApplyUpgradeEvents({
                tokenId,
                order: "desc",
                limit: 1,
            }).then(events => events[0] ?? null),
        { enabled: !!tokenId },
    )
}

export const getCancelUpgradeEventsKey = (tokenId?: string) => [
    "CANCEL_UPGRADE_EVENTS",
    tokenId,
]
/**
 *  Get the cancelUpgrade events of a token id (optional for better DX with react-query)
 * @param params  the params to get the apply upgrade events
 * @rejects in case of VM error
 * @returns the apply upgrade events
 */
export const useGetCancelUpgradeEvents = (params: GetEventsProps) => {
    return useQuery(
        getCancelUpgradeEventsKey(params.tokenId),
        () => TokenAuctionService.getCancelUpgradeEvents(params),
        { enabled: !!params.tokenId },
    )
}

export const getSuccessfulAuctionForSellerKey = (seller?: string) => [
    "SUCCESSFUL_AUCTION_FOR_SELLER",
    seller,
]
export const useGetSuccessfulAuctionForSeller = (seller?: string) => {
    return useQuery(
        getSuccessfulAuctionForSellerKey(seller),
        () => TokenAuctionService.getSuccessfulAuctionBySeller(seller),
        { enabled: !!seller },
    )
}

export const getLastCancelUpgradeEventKey = (tokenId?: string) => [
    "LAST_CANCEL_UPGRADE_EVENT",
    tokenId,
]
/**
 * Get the last cancel upgrade event of a token id (optional for better DX with react-query)
 * @param tokenId  the token id to get the last apply upgrade event
 * @returns  the last apply upgrade event
 */
export const useLastCancelUpgradeEvent = (tokenId?: string) => {
    return useQuery(
        getLastCancelUpgradeEventKey(tokenId),
        () =>
            TokenAuctionService.getCancelUpgradeEvents({
                tokenId,
                order: "desc",
                limit: 1,
            }).then(events => events[0] ?? null),
        { enabled: !!tokenId },
    )
}

export const getLevelChangedEventsKey = (tokenId?: string) => [
    "LEVEL_CHANGED_EVENTS",
    tokenId,
]
/**
 *  Get the levelChanged events of a token id (optional for better DX with react-query)
 * @param params  the params to get the apply upgrade events
 * @rejects in case of VM error
 * @returns the apply upgrade events
 */
export const useGetLevelChangedEvents = (params: GetEventsProps) => {
    return useQuery(
        getLevelChangedEventsKey(params.tokenId),
        () => TokenAuctionService.getLevelChangedEvents(params),
        { enabled: !!params.tokenId },
    )
}

export const getLastLevelChangedEventKey = (tokenId?: string) => [
    "LAST_LEVEL_CHANGED_EVENT",
    tokenId,
]
/**
 * Get the last apply upgrade event of a token id (optional for better DX with react-query)
 * @param tokenId  the token id to get the last apply upgrade event
 * @returns  the last apply upgrade event
 */
export const useLastLevelChangedEvent = (tokenId?: string) => {
    return useQuery(
        getLastLevelChangedEventKey(tokenId),
        () =>
            TokenAuctionService.getLevelChangedEvents({
                tokenId,
                order: "desc",
                limit: 1,
            }).then(events => events[0] ?? null),
        { enabled: !!tokenId },
    )
}

export const getAuctionQueryKey = (tokenId: string) => [
    "TOKEN_AUCTION_DETAILS",
    tokenId,
]

export const useGetAuction = (tokenId: string) => {
    return useQuery(
        getAuctionQueryKey(tokenId),
        () => ClockAuctionService.getTokenAuction(tokenId),
        { enabled: !!tokenId },
    )
}

export const getCurrentAuctionPriceQueryKey = (tokenId: string) => [
    "AUCTION_CURRENT_PRICE",
    tokenId,
]

export const useGetAuctionCurrentPrice = (tokenId: string) => {
    return useQuery(
        getCurrentAuctionPriceQueryKey(tokenId),
        () => ClockAuctionService.getAuctionCurrentPrice(tokenId),
        { enabled: !!tokenId },
    )
}

export const getAuctionHasWhitelistKey = (tokenId: string) => [
    "AUCTION_HAS_WHITELIST",
    tokenId,
]

export const useGetAuctionHasWhitelist = (tokenId: string) => {
    return useQuery(
        getAuctionHasWhitelistKey(tokenId),
        () => ClockAuctionService.getAuctionHasWhitelist(tokenId),
        { enabled: !!tokenId },
    )
}

export const useGetTransferCooldown = () => {
    return useQuery(
        ["TRANSFER_COOLDOWN"],
        async () => {
            return TokenAuctionService.getTransferCooldownMs()
        },
        { cacheTime: Infinity },
    )
}

export const getTransferReceiverKey = (
    tokenId: string,
    targetAddress: string,
) => ["CAN_TRANSFER_ERRORS", tokenId, targetAddress]

export const useCheckTransferReceiver = (
    tokenDetails: CompleteTokenDetails,
    targetAddress: string,
) => {
    return useQuery(
        getTransferReceiverKey(tokenDetails.id, targetAddress),
        async () => {
            return TokenAuctionService.checkTransferReceiver(
                tokenDetails,
                targetAddress,
            )
        },
    )
}

export const getAddAuctionWhitelistEventsKey = (auctionId?: number) => [
    "ADD_AUCTION_WHITELIST",
    auctionId,
]

export const useGetAddAuctionWhitelistEvents = (params: GetEventsProps) => {
    return useQuery(
        getAddAuctionWhitelistEventsKey(params.auctionId),
        () => TokenAuctionService.getAddAuctionWhiteListEvents(params),
        { enabled: !!params.auctionId },
    )
}
