const environment = import.meta.env.MODE
const isProduction = environment === "production"

const trace = (() => {
    if (isProduction) {
        return () => ({})
    }
    return (...args: unknown[]) => {
        console.trace(...args)
    }
})()

const debug = (() => {
    if (isProduction) {
        return () => ({})
    }
    return (...args: unknown[]) => {
        console.debug(...args)
    }
})()

const log = (() => {
    if (isProduction) {
        return () => ({})
    }
    return (...args: unknown[]) => {
        console.log(...args)
    }
})()

const info = (() => {
    if (isProduction) {
        return () => ({})
    }
    return (...args: unknown[]) => {
        console.info(...args)
    }
})()

const warn = (...args: unknown[]) => console.warn(...args)

const error = (...args: unknown[]) => console.error(...args)

export const logger = {
    trace,
    debug,
    log,
    info,
    warn,
    error,
}
