import { Box, HTMLChakraProps } from "@chakra-ui/react"
import { animatedgradient } from ".."

type Props = {
    gradient: string
    children: React.ReactNode
    borderWidth: number
    borderColor?: string
    renderInnerBorder?: boolean
} & HTMLChakraProps<"div">

/**
 *  Renders a box with an animated gradient border and a background color
 * @param gradient string - gradient css property
 * @param children React.ReactNode
 * @param borderWidth number - border width in chakra sizes
 * @param borderColor string - border color
 * @param renderInnerBorder boolean - whether to render an inner border
 *
 * @returns  React.ReactNode
 */
export const BoxWithGradientBorder: React.FC<Props> = ({
    children,
    gradient,
    borderWidth,
    borderColor,
    renderInnerBorder = true,
    ...otherProps
}) => {
    const borderWidthCssVariable = `var(--chakra-sizes-${borderWidth})`
    return (
        <Box
            {...(renderInnerBorder && {
                borderWidth: borderWidth,
                background: borderColor,
            })}
            borderRadius={borderWidthCssVariable}
            _after={{
                content: "''",
                position: "absolute",
                top: `calc(-1 * ${borderWidthCssVariable})`,
                left: `calc(-1 * ${borderWidthCssVariable})`,
                height: `calc(100% + ${borderWidthCssVariable}  * 2)`,
                width: `calc(100% + ${borderWidthCssVariable}  * 2)`,
                background: gradient,
                borderRadius: `calc(2 * ${borderWidthCssVariable})`,
                zIndex: -1,
                animation: `${animatedgradient} 15s ease alternate infinite`,
                backgroundSize: "400% 400%",
                // filter: "blur(2px)",
            }}
            {...otherProps}>
            {children}
        </Box>
    )
}
