import { abi } from "thor-devkit"

export const removeAuctionWhiteList: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
        {
            name: "_address",
            type: "address",
        },
    ],
    name: "removeAuctionWhiteList",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const supportsInterface: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_interfaceId",
            type: "bytes4",
        },
    ],
    name: "supportsInterface",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const sendBonusTo: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_to",
            type: "address",
        },
        {
            name: "_amount",
            type: "uint256",
        },
    ],
    name: "sendBonusTo",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const name: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
        {
            name: "",
            type: "string",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const isNormalToken: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_target",
            type: "address",
        },
    ],
    name: "isNormalToken",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const getApproved: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "getApproved",
    outputs: [
        {
            name: "",
            type: "address",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const approve: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_to",
            type: "address",
        },
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "approve",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const operators: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "",
            type: "address",
        },
    ],
    name: "operators",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const totalSupply: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
        {
            name: "",
            type: "uint256",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const isToken: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_target",
            type: "address",
        },
    ],
    name: "isToken",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const transferFrom: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_from",
            type: "address",
        },
        {
            name: "_to",
            type: "address",
        },
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "transferFrom",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const getTokenParams: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_level",
            type: "uint8",
        },
    ],
    name: "getTokenParams",
    outputs: [
        {
            name: "",
            type: "uint256",
        },
        {
            name: "",
            type: "uint64",
        },
        {
            name: "",
            type: "uint64",
        },
        {
            name: "",
            type: "uint64",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const auctionCount: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "auctionCount",
    outputs: [
        {
            name: "",
            type: "uint256",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const createDirectionalSaleAuction: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
        {
            name: "_price",
            type: "uint128",
        },
        {
            name: "_duration",
            type: "uint64",
        },
        {
            name: "_toAddress",
            type: "address",
        },
    ],
    name: "createDirectionalSaleAuction",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const unpause: abi.Function.Definition = {
    constant: false,
    inputs: [],
    name: "unpause",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const pause: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_badGuy",
            type: "address",
        },
    ],
    name: "addToBlackList",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const bid: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "bid",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
}
export const blacklist: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "",
            type: "address",
        },
    ],
    name: "blackList",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const removeFromBlackList: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_innocent",
            type: "address",
        },
    ],
    name: "removeFromBlackList",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const canTransfer: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "canTransfer",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const paused: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "paused",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const upgradeTo: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
        {
            name: "_toLvl",
            type: "uint8",
        },
    ],
    name: "upgradeTo",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const ownerOf: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "ownerOf",
    outputs: [
        {
            name: "",
            type: "address",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const transferCooldown: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "transferCooldown",
    outputs: [
        {
            name: "",
            type: "uint64",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const setSaleAuctionAddress: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_address",
            type: "address",
        },
    ],
    name: "setSaleAuctionAddress",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const balanceOf: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_owner",
            type: "address",
        },
    ],
    name: "balanceOf",
    outputs: [
        {
            name: "",
            type: "uint256",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const applyUpgrade: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_toLvl",
            type: "uint8",
        },
    ],
    name: "applyUpgrade",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const addToken: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_addr",
            type: "address",
        },
        {
            name: "_lvl",
            type: "uint8",
        },
        {
            name: "_onUpgrade",
            type: "bool",
        },
        {
            name: "_applyUpgradeTime",
            type: "uint64",
        },
        {
            name: "_applyUpgradeBlockno",
            type: "uint64",
        },
    ],
    name: "addToken",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const owner: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [
        {
            name: "",
            type: "address",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const symbol: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
        {
            name: "",
            type: "string",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const cancelAuction: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "cancelAuction",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const addOperator: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_operator",
            type: "address",
        },
    ],
    name: "addOperator",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const getMetadata: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "getMetadata",
    outputs: [
        {
            name: "",
            type: "address",
        },
        {
            name: "",
            type: "uint8",
        },
        {
            name: "",
            type: "bool",
        },
        {
            name: "",
            type: "bool",
        },
        {
            name: "",
            type: "uint64",
        },
        {
            name: "",
            type: "uint64",
        },
        {
            name: "",
            type: "uint64",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const transfer: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_to",
            type: "address",
        },
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "transfer",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const downgradeTo: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
        {
            name: "_toLvl",
            type: "uint8",
        },
    ],
    name: "downgradeTo",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const cancelUpgrade: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "cancelUpgrade",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const setTransferCooldown: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_cooldown",
            type: "uint64",
        },
    ],
    name: "setTransferCooldown",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const createSaleAuction: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
        {
            name: "_startingPrice",
            type: "uint128",
        },
        {
            name: "_endingPrice",
            type: "uint128",
        },
        {
            name: "_duration",
            type: "uint64",
        },
    ],
    name: "createSaleAuction",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const idToOwner: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "",
            type: "uint256",
        },
    ],
    name: "idToOwner",
    outputs: [
        {
            name: "",
            type: "address",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const tokenURI: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "tokenURI",
    outputs: [
        {
            name: "",
            type: "string",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const addAuctionWhiteList: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
        {
            name: "_address",
            type: "address",
        },
    ],
    name: "addAuctionWhiteList",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}

export const xTokenCount: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "xTokenCount",
    outputs: [
        {
            name: "",
            type: "uint64",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const saleAuction: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "saleAuction",
    outputs: [
        {
            name: "",
            type: "address",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const ownerToId: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "",
            type: "address",
        },
    ],
    name: "ownerToId",
    outputs: [
        {
            name: "",
            type: "uint256",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const transferOwnership: abi.Function.Definition = {
    constant: false,
    inputs: [
        {
            name: "newOwner",
            type: "address",
        },
    ],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
}
export const normalTokenCount: abi.Function.Definition = {
    constant: true,
    inputs: [],
    name: "normalTokenCount",
    outputs: [
        {
            name: "",
            type: "uint64",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
export const isX: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_target",
            type: "address",
        },
    ],
    name: "isX",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
