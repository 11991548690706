import { CLOCK_AUCTION_CONTRACT, ClockAuctionABI } from "@/constants"
import { AuctionType, AuctionWithMetadata } from "@/models/Auction.ts"
import ConnexService from "../ConnexService"
import { getMetadata } from "@/services/TokenAuctionService/TokenAuctionService"
import { scaleNumberDown } from "@/utils/FormattingUtils/FormattingUtils.ts"
import { TokenAuctionService } from ".."

const connex = ConnexService.getConnexInstance()

/**
 *  Get Auction by tokenId
 * @rejects in case of VM error or no auction found
 * @returns auction object
 */
export const getTokenAuction = async (
    tokenId: string,
): Promise<AuctionWithMetadata> => {
    const res = await connex.thor
        .account(CLOCK_AUCTION_CONTRACT)
        .method(ClockAuctionABI.functions.getAuction)
        .call(tokenId)

    if (res.decoded.autionId === "0")
        return Promise.reject(new Error("No auction found"))

    const price = await getAuctionCurrentPrice(tokenId)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    const tokenMetadata = await getMetadata(tokenId)

    const isWhitelisted = Boolean(await getAuctionHasWhitelist(tokenId))

    let whitelistedEvents = undefined

    if (isWhitelisted) {
        whitelistedEvents =
            await TokenAuctionService.getAddAuctionWhiteListEvents({
                auctionId: res.decoded.autionId,
                order: "desc",
                limit: 1,
            })
    }

    const auctionType = (() => {
        if (isWhitelisted) return AuctionType.DIRECT_SALE
        if (res.decoded.startingPrice === res.decoded.endingPrice)
            return AuctionType.FIXED_PRICE
        return AuctionType.DUTCH
    })()

    return {
        tokenId: tokenId,
        tokenOwner: res.decoded.seller,
        auctionId: res.decoded.autionId,
        startingPrice: scaleNumberDown(res.decoded.startingPrice, 18, 18),
        endingPrice: scaleNumberDown(res.decoded.endingPrice, 18, 18),
        currentPrice: price,
        duration: parseInt(res.decoded.duration),
        startedAt: parseInt(res.decoded.startedAt),
        type: auctionType,
        tokenMetadata,
        isWhitelisted: isWhitelisted,
        whitelistedAddress:
            whitelistedEvents && whitelistedEvents.length > 0
                ? whitelistedEvents[0].candidate
                : undefined,
    }
}

/**
 * Get the current price of an auction for a specific token id
 * (if it is a dutch auction, the price changes over time)
 *
 * @param tokenId
 * @returns
 */
export const getAuctionCurrentPrice = async (
    tokenId: string,
): Promise<string> => {
    const res = await connex.thor
        .account(CLOCK_AUCTION_CONTRACT)
        .method(ClockAuctionABI.functions.getCurrentPrice)
        .call(tokenId)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return scaleNumberDown(res.decoded[0], 18, 18)
}

/**
 * See if auction has a whitelist
 *
 * @param tokenId
 * @returns
 */
export const getAuctionHasWhitelist = async (
    tokenId: string,
): Promise<string> => {
    const res = await connex.thor
        .account(CLOCK_AUCTION_CONTRACT)
        .method(ClockAuctionABI.functions.hasWhiteList)
        .call(tokenId)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return res.decoded[0]
}
