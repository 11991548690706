import {
    HStack,
    Heading,
    Icon,
    Skeleton,
    Tag,
    Text,
    VStack,
} from "@chakra-ui/react"
import { CurrentBalance, VechainPictogram } from ".."
import { BanknotesIcon } from "@heroicons/react/24/solid"
import BigNumber from "bignumber.js"
import { useBalance } from "@/hooks"
import { VET } from "@/constants"
import { useAccountBalance } from "@/api/hooks"
import { BaseTooltip } from "@/components"

/**
 * BalanceRequirement
 * @param address - the address of the account to check the balance of (required)
 * @param balanceRequirement - the balance requirement to check against (required)
 * @param headingSize - the size of the heading (optional)
 * @param iconSize - the size of the icon (optional)
 * @param tagSize - the size of the tag (optional)
 * @param showBalanceAlert - whether to show the balance alert (optional) - defaults to true
 * @param showTitle - whether to show the title (optional) - defaults to true
 * @param showAccountBalance - whether to show the account balance (optional) - defaults to false
 * @param showRiskOwnershipMessage - whether to show the risk ownership message (optional) - defaults to true
 * @param showRequiredVET - whether to show the required VET (optional) - defaults to false
 * @param balanceRequiredFontSize - the font size of the balance requirement (optional) - defaults to md
 */
type Props = {
    address: string
    balanceRequirement: string
    headingSize?: string
    iconSize?: number
    tagSize?: string
    showBalanceAlert?: boolean
    showTitle?: boolean
    showAccountBalance?: boolean
    showRiskOwnershipMessage?: boolean
    showRequiredVET?: boolean
    balanceRequiredFontSize?: string
}
export const BalanceRequirement: React.FC<Props> = ({
    address,
    balanceRequirement,
    headingSize = "sm",
    iconSize = 6,
    tagSize = "md",
    showBalanceAlert = true,
    showTitle = true,
    showAccountBalance = false,
    showRiskOwnershipMessage = true,
    showRequiredVET = false,
    balanceRequiredFontSize = "md",
}) => {
    const {
        data: accountBalance,
        isLoading: balanceLoading,
        fetchStatus,
    } = useAccountBalance(address)

    const isAccountBalanceIdle = fetchStatus === "idle"

    const { parsedBalance: parsedAccountBalance } = useBalance({
        balance: accountBalance?.balance ?? "0",
        token: VET,
        includeDecimals: true,
    })

    const isMeetingBalanceRequirement = BigNumber(
        parsedAccountBalance,
    ).isGreaterThanOrEqualTo(BigNumber(balanceRequirement))

    const { formattedBalance: formattedBalanceRequirement } = useBalance({
        balance: balanceRequirement,
        token: VET,
    })

    if (!showTitle && !showBalanceAlert)
        return (
            <Skeleton
                data-cy="balance-requirement-only-tag"
                isLoaded={!balanceLoading}
                speed={isAccountBalanceIdle ? 0 : 0.8}>
                <Tag
                    colorScheme={isMeetingBalanceRequirement ? "green" : "red"}
                    size={tagSize}>
                    <HStack spacing={0.5}>
                        <Text>{formattedBalanceRequirement}</Text>
                        <VechainPictogram boxSize={iconSize} />
                    </HStack>
                </Tag>
            </Skeleton>
        )

    return (
        <VStack
            align={"flex-start"}
            spacing={[0]}
            w="full"
            data-cy="balance-requirement-container">
            {showTitle && (
                <HStack
                    justify={"space-between"}
                    alignItems={"center"}
                    w="full">
                    <HStack spacing={1}>
                        <Icon as={BanknotesIcon} boxSize={iconSize} />
                        <Heading
                            fontWeight={"bold"}
                            size={headingSize}
                            data-cy="balance-requirement-title">
                            {"Balance requirement"}
                        </Heading>
                    </HStack>
                    <Skeleton
                        isLoaded={!balanceLoading || showRequiredVET}
                        speed={isAccountBalanceIdle ? 0 : 0.8}>
                        <BaseTooltip
                            text={
                                address
                                    ? isMeetingBalanceRequirement
                                        ? "Your account meets the balance requirement"
                                        : "Your account does not meet the balance requirement"
                                    : "Connect your wallet to see if you meet balance requirement"
                            }
                            children={
                                <Tag
                                    colorScheme={
                                        address
                                            ? isMeetingBalanceRequirement
                                                ? "green"
                                                : "red"
                                            : "gray"
                                    }
                                    size={tagSize}>
                                    <HStack spacing={0.5}>
                                        <Text
                                            data-cy="formatted-balance-requirement"
                                            fontSize={balanceRequiredFontSize}>
                                            {formattedBalanceRequirement}
                                        </Text>
                                        <VechainPictogram boxSize={iconSize} />
                                    </HStack>
                                </Tag>
                            }
                        />
                    </Skeleton>
                </HStack>
            )}
            <VStack mt={[2, 0]} spacing={0.5} alignItems={"flex-start"}>
                {showAccountBalance && address && (
                    <CurrentBalance address={address} />
                )}
                {showBalanceAlert && (
                    <Skeleton
                        isLoaded={!balanceLoading}
                        speed={isAccountBalanceIdle ? 0 : 0.8}>
                        {isMeetingBalanceRequirement ? (
                            <Text
                                fontWeight={"medium"}
                                fontSize="sm"
                                data-cy="balance-requirement-meets-requirement"
                                color="green.500">
                                {"Your account meets the balance requirement"}
                            </Text>
                        ) : (
                            <Text
                                fontWeight={"medium"}
                                fontSize="sm"
                                data-cy="balance-requirement-does-not-meet-requirement"
                                color="red.500">
                                {`Your account does not meet the balance requirement.
                                ${
                                    showRiskOwnershipMessage
                                        ? "Your node ownership is at risk. Please top up your account or transfer to another account."
                                        : ""
                                }`}
                            </Text>
                        )}
                    </Skeleton>
                )}
            </VStack>
        </VStack>
    )
}
