import { cssVar, defineStyle, defineStyleConfig } from "@chakra-ui/react"

const $startColor = cssVar("skeleton-start-color")
const $endColor = cssVar("skeleton-end-color")

const gray = defineStyle({
    _light: {
        [$startColor.variable]: "colors.gray.200", //changing startColor to red.100
        [$endColor.variable]: "colors.gray.500", // changing endColor to red.400
    },
    _dark: {
        [$startColor.variable]: "colors.gray.600", //changing startColor to red.800
        [$endColor.variable]: "colors.gray.800", // changing endColor to red.600
    },
})
export const skeletonTheme = defineStyleConfig({
    variants: { gray },
    defaultProps: {
        variant: "gray",
    },
})
