export const VechainLightPurple = "#28008C"
export const VechainBright = "rgba(31,0,111,0.18)"
export const VechainLightTurquoise = "#00BED7"
export const VechainLightGreen = "#82BE00"

export const VechainDarkPurple = "#1F006F"
export const VechainDarkTurquoise = "#0094A5"
export const VechainDarkGreen = "#5A8000"

export const VechainPurple = (isDark = false) =>
    isDark ? VechainDarkPurple : VechainLightPurple

export const VechainBrightPurple = (isDark = false) =>
    isDark ? VechainDarkPurple : VechainBright

export const VechainTurquoise = (isDark = false) =>
    isDark ? VechainDarkTurquoise : VechainLightTurquoise

export const VechainGreen = (isDark = false) =>
    isDark ? VechainDarkGreen : VechainLightGreen

export const VechainLinearGradient = (isDark = false, direction = "60deg") =>
    `linear-gradient(${direction}, ${VechainPurple(
        isDark,
    )} 13%, ${VechainTurquoise(isDark)} 60%, ${VechainGreen(isDark)} 100%)`

export const VechainLinearGradientWithoutPurple = (
    isDark = false,
    direction = "60deg",
) =>
    `linear-gradient(${direction}, ${VechainTurquoise(isDark)} , ${VechainGreen(
        isDark,
    )} )`

export const VechainLinearGradientPurple = (
    isDark = false,
    direction = "60deg",
) =>
    `linear-gradient(${direction}, ${VechainBrightPurple(
        isDark,
    )} , ${VechainPurple(isDark)} )`

export const ProgressBarGradient = () =>
    `linear-gradient(60deg, #30265f40 1%, #30265F 100%)`

export const TooltipBackgroundColor = (isDark = false) =>
    isDark ? "#CBD5E0" : "#26303E"

export const TooltipTextColor = (isDark = false) =>
    isDark ? "#171923" : "white"

export const VetInputErrorBorderColor = (isDark = false) =>
    isDark ? "#FC8181" : "#E53E3E"

export const VetInputErrorBoxShadow = (isDark = false) =>
    isDark ? "0 0 0 1px #FC8181" : "0 0 0 1px #E53E3E"
