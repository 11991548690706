import {
    NodeParameters,
    NodeVotingTier,
    allNodeStrengthLevel,
} from "@/constants"
import {
    CancelUpgradeEvent,
    CompleteTokenDetails,
    TokenMetadata,
} from "@/models"
import BigNumber from "bignumber.js"
import { AddressUtils, dayjs } from "@/utils"
import { ConnexService } from "@/services"
import { isValid } from "../AddressUtils/AddressUtils"

/**
 * Check if a node is at the max level (economic or X)
 * @param level the level of the node to check
 * @returns true if the node is at the max level (economic or X)
 */
export const isNodeMaxLevel = (level: string): boolean => {
    return level === "7" || level === "3"
}

export const levelKeyExists = (level: string): boolean => {
    return (
        !!NodeParameters[level] &&
        !!NodeVotingTier[level] &&
        !!allNodeStrengthLevel[level]
    )
}

export enum CannotUpgradeNodeReason {
    onUpgrade = "onUpgrade",
    onAuction = "onAuction",
    maxLevel = "maxLevel",
    balanceRequirement = "balanceRequirement",
    unknown = "unknown",
}

export type ICannotUpragdeReasonToText = {
    [key in CannotUpgradeNodeReason]: string
}

export const cannotUpgradeReasonToText: ICannotUpragdeReasonToText = {
    onUpgrade: "You can't upgrade a node which is on upgrade",
    onAuction: "You can't upgrade a node which is on auction",
    maxLevel: "Your node is already at the max level",
    balanceRequirement: "You're not meeting the balance required for ",
    unknown: "An unknown error occurre while checking if you can upgrade",
}
/**
 *  Check if a node can be upgraded to the next level, throwing an error if not
 * @param balance the balance of the node to check if can be upgraded
 * @param nodeMetadata the metadata of the node to check if can be upgraded
 * @throws {CannotUpgradeNodeReason} if the node can't be upgraded for a specific reason
 */
export const canUpgradeNode = (
    balance: string,
    nodeMetadata: TokenMetadata,
): void => {
    if (nodeMetadata.onUpgrade)
        throw new Error(CannotUpgradeNodeReason.onUpgrade)
    if (nodeMetadata.onAuction)
        throw new Error(CannotUpgradeNodeReason.onAuction)
    if (isNodeMaxLevel(nodeMetadata.level))
        throw new Error(CannotUpgradeNodeReason.maxLevel)
    const nextLevelToken = NodeParameters[parseInt(nodeMetadata.level) + 1]
    if (!nextLevelToken) throw new Error(CannotUpgradeNodeReason.maxLevel)

    const isMeetingBalanceRequirement = BigNumber(
        balance,
    ).isGreaterThanOrEqualTo(BigNumber(nextLevelToken.minBalance))

    if (!isMeetingBalanceRequirement)
        throw new Error(CannotUpgradeNodeReason.balanceRequirement)
}

/**
 * Get the accurate to second percentage of completion of an upgrade
 * @param startingDate  the date when the upgrade started (in unix format)
 * @param ripeSeconds  the number of seconds required to complete the upgrade
 * @param safePercentage always return a percentage between 0 and 100
 * @returns the percentage of completion of the upgrade (accurate to second)
 */
export const getUpgradePercentageCompletion = (
    startingDate: number,
    ripeSeconds: number,
    safePercentage = true,
): number => {
    const startingDateMoment = dayjs.unix(startingDate)
    const endingDateMoment = startingDateMoment.add(ripeSeconds, "seconds")
    const now = dayjs()

    const totalUpgradeTime = endingDateMoment.diff(startingDateMoment, "second")
    const timePassed = now.diff(startingDateMoment, "second")

    let percentage = (timePassed / totalUpgradeTime) * 100
    if (safePercentage) percentage = Math.min(100, Math.max(0, percentage))

    return percentage
}

export const checkIfUpgradeFailed = async (
    cancelUpgradeEvent: CancelUpgradeEvent,
): Promise<boolean> => {
    const eventTransaction = await ConnexService.getTransaction(
        cancelUpgradeEvent.meta.txID,
    )
    return !AddressUtils.compareAddresses(
        eventTransaction?.origin,
        cancelUpgradeEvent.owner,
    )
}

export type CannotTransferTokenKeys =
    | "onAuction"
    | "onUpgrade"
    | "cooldown"
    | "invalidAddress"
    | "targetIsOwner"
    | "alreadyOwnToken"
    | "targetIsContract"
    | "balanceRequirement"
    | "unknown"

export type CannotTransferTokenResponse = {
    key: CannotTransferTokenKeys
    kind: "error" | "warning"
    message: string
} | null

/**
 *  Check if a node can be transferred, considering only the local state of the node (not the target address requirements)
 * @param completeTokenDetails  the complete token details of the node to check if can be transferred
 * @param targetAddress  the target address of the node to check if can be transferred
 * @param transferCooldownMs  the number of milliseconds required to pass before a node can be transferred again
 * @returns  an error message if the node can't be transferred, undeifned otherwise
 */
export const localCanTransferNodeValidation = (
    completeTokenDetails: CompleteTokenDetails,
    targetAddress: string,
    transferCooldownMs: number,
): CannotTransferTokenResponse => {
    const { metadata } = completeTokenDetails

    if (metadata.onAuction)
        return {
            key: "onAuction",
            kind: "error",
            message: "You can't transfer a node which is on auction",
        }
    if (metadata.onUpgrade)
        return {
            key: "onUpgrade",
            kind: "error",
            message: "You can't transfer a node which is on upgrade",
        }

    const nextTransferEpoch = dayjs
        .unix(parseInt(metadata.lastTransferTime))
        .add(transferCooldownMs, "milliseconds")

    const isCooledDown = dayjs().isBefore(nextTransferEpoch)

    if (isCooledDown)
        return {
            key: "cooldown",
            kind: "error",
            message: `Please, wait ${dayjs().to(
                nextTransferEpoch,
                true,
            )} before transferring this node again`,
        }

    if (!isValid(targetAddress))
        return {
            key: "invalidAddress",
            kind: "error",
            message: `Receiver address is invalid`,
        }
    if (metadata.owner === targetAddress)
        return {
            key: "targetIsOwner",
            kind: "error",
            message: "Target address is the owner of this node",
        }

    return null
}
