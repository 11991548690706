import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { Account, Network } from "@/models"
import { LocalStorageService } from "@/services"

interface State {
    setConnectedAccount: (data: { account: Account; network: Network }) => void
    clear: () => void
    account?: Account
    network?: Network
    historicalRewardsView: "chart" | "table"
    sessionStartTime?: number
    hasClosedMaxLevelAlert: boolean
    setHistoricalRewardsView: (view: "chart" | "table") => void
    setSessionStartTime: (sessionStartTime: number) => void
    setHasClosedMaxLevelAlert: (hasClosedMaxLevelAlert: boolean) => void
}

export const useAccountStore = create<State>()(
    devtools(
        persist(
            (set, get) => ({
                account: undefined,
                network: undefined,
                hasAcceptedTos: false,
                historicalRewardsView: "chart",
                sessionStartTime: undefined,
                hasClosedMaxLevelAlert: false,
                setConnectedAccount: ({ account, network }) => {
                    set({
                        ...get(),
                        account,
                        network,
                        sessionStartTime: new Date().getTime(),
                    })
                },
                setHistoricalRewardsView: view => {
                    set({ ...get(), historicalRewardsView: view })
                },
                setSessionStartTime: sessionStartTime => {
                    set({ ...get(), sessionStartTime })
                },
                setHasClosedMaxLevelAlert: hasClosedMaxLevelAlert => {
                    set({ ...get(), hasClosedMaxLevelAlert })
                },
                clear: () =>
                    set({
                        account: undefined,
                        network: undefined,
                        historicalRewardsView: "chart",
                        sessionStartTime: undefined,
                    }),
            }),
            {
                name: LocalStorageService.ACCOUNT_STORAGE_KEY,
            },
        ),
    ),
)
