import React, { useEffect, useMemo } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import {
    Alert,
    AlertIcon,
    Spinner,
    useColorModeValue,
    useToken,
} from "@chakra-ui/react"
import { useRewardsHistory, useVthoExchangeRate } from "@/api/hooks"
import { FormattingUtils, dayjs } from "@/utils"
import { useCurrencyStore } from "@/store"
import { VTHO, VechainDarkPurple, VechainLightPurple } from "@/constants"
import { RewardsHistoryEntry } from "@/models"
import { OpUnitType } from "dayjs"
const { humanNumber, convertToFiatBalance } = FormattingUtils

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const getOptions = (textColor: string) => ({
    responsive: true,
    scales: {
        y: {
            ticks: { color: textColor },
        },
        x: {
            ticks: { color: textColor },
        },
    },
    plugins: {
        legend: {
            position: "bottom" as const,
            labels: {
                color: textColor,
            },
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
})

type Props = {
    address: string
}
export const HistoricalRewardsChart: React.FC<Props> = ({ address }) => {
    const [white, gray500, yellow500] = useToken(
        // the key within the theme, in this case `theme.colors`
        "colors",
        // the subkey(s), resolving to `theme.colors.red.100`
        ["white", "gray.500", "yellow.500"],
        // a single fallback or fallback array matching the length of the previous arg
    )
    const vechainPurple = useColorModeValue(
        VechainLightPurple,
        VechainDarkPurple,
    )

    const textColor = useColorModeValue(gray500, white)

    const [pageSize, setPageSize] = React.useState<number>(10)

    const {
        data: historicalRewardsInfo,
        isLoading,
        isError,
        fetchStatus,
    } = useRewardsHistory(address, 1, pageSize)

    // we have to ensure the chart contains all the data available
    useEffect(() => {
        if (!historicalRewardsInfo) return
        if (historicalRewardsInfo.totalPages === 1) return
        setPageSize(historicalRewardsInfo.totalPages * pageSize)
    }, [historicalRewardsInfo])

    const isIdle = fetchStatus === "idle"
    let groupBy: OpUnitType | undefined = "day"

    const { currency } = useCurrencyStore()
    const { data: exchangeRate } = useVthoExchangeRate(currency.coingeckoID)

    const [selectedCurrency] = React.useState<string>(VTHO.symbol)

    const reversedRewards = useMemo(() => {
        if (!historicalRewardsInfo || !historicalRewardsInfo.history) return []
        if (historicalRewardsInfo.history.length > 360) groupBy = "month"

        //clone array to avoid mutating the original array
        const clonedHistory: RewardsHistoryEntry[] = JSON.parse(
            JSON.stringify(historicalRewardsInfo?.history),
        )

        const history = clonedHistory.reduce(
            (resp: RewardsHistoryEntry[], obj: RewardsHistoryEntry) => {
                const existedEntry = resp.find(entry => {
                    return dayjs
                        .unix(entry.timestamp)
                        .isSame(dayjs.unix(obj.timestamp), groupBy)
                })

                if (!existedEntry) return [...resp, obj]
                existedEntry.amount = (
                    parseFloat(existedEntry.amount) + parseFloat(obj.amount)
                ).toString()
                return resp
            },
            [],
        )
        return history.reverse()
    }, [historicalRewardsInfo])

    const labelField = groupBy === "day" ? "D MMM YYYY" : "MMM YYYY"
    const labels = reversedRewards.map(history =>
        dayjs.unix(history.timestamp).format(labelField),
    )

    const prepareDataset = (rewards: RewardsHistoryEntry[], state: number) => {
        return (
            rewards.map(history => {
                if (history.state != state) return 0
                if (selectedCurrency === VTHO.symbol) {
                    return parseFloat(history.amount)
                }
                const fiatBalance = convertToFiatBalance(
                    history.amount.toString(),
                    exchangeRate ?? 0,
                    0,
                    2,
                )
                return parseFloat(humanNumber(fiatBalance, fiatBalance, true))
            }) ?? []
        )
    }

    const data: ChartData<"bar", number[], string> = useMemo(() => {
        return {
            labels,
            datasets: [
                {
                    label: "Claimed rewards",
                    data: prepareDataset(reversedRewards, 2),
                    backgroundColor: vechainPurple,
                },
                {
                    label: "Pending rewards",
                    data: prepareDataset(reversedRewards, 0),
                    backgroundColor: yellow500,
                },
            ],
        }
    }, [reversedRewards])

    if (isLoading && !isIdle) return <Spinner alignSelf={"center"} size="lg" />

    if (isError)
        return (
            <Alert status="error">
                <AlertIcon />
                {
                    "An error occurred while fetching historical rewards. Please try again."
                }
            </Alert>
        )
    return (
        <Bar
            data-cy="historical-rewards-chart"
            options={getOptions(textColor)}
            data={data}
        />
    )
}
