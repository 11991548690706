import { defineStyleConfig } from "@chakra-ui/react"

export const alertTheme = defineStyleConfig({
    baseStyle: {
        container: {
            p: 4,
            borderRadius: "xl",
        },
    },

    sizes: {
        sm: {
            container: {
                p: 2,
            },
        },
        md: {
            container: {
                p: 4,
            },
        },
        lg: {
            container: {
                p: 6,
            },
        },
    },
    defaultProps: {
        size: "md",
    },
})
