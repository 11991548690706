import { RewardInfo, RewardsHistory } from "@/models"
import { axios } from "../axios"
import { AnalyticsUtils } from "@/utils"
import { AnalyticsEventsEnum, ApiCallStateEnum } from "@/constants"
import { AxiosError } from "axios"
import dayjs from "dayjs"

/**
 * Get the reward info status for a specific address
 * @param address the address to get the reward info
 * @returns the reward info @GetRewardInfoResponse
 */
export const getRewardInfo = async (address?: string) => {
    try {
        if (!address) return Promise.reject("address is empty")

        const res = await axios.get<RewardInfo>(`/${address}`)

        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.API_CALL, {
            state: ApiCallStateEnum.SUCCESS,
            endpoint: "getRewardInfo",
        })
        return res.data
    } catch (e) {
        const error = e as AxiosError
        AnalyticsUtils.publishAxiosError(error, "getRewardInfo")
        throw e
    }
}

/**
 * claim the reward for a specific address
 * @param address the address to claim the reward
 * @param authorityProof the authority proof of the address. This should be a message signed by the address PK
 * @returns the claim reward response @ClaimRewardResponse
 */
export const claimReward = async ({
    address,
    authorityProof,
}: {
    address: string
    authorityProof: string
}) => {
    try {
        const res = await axios.post<Record<string, never>>(
            `/${address}`,
            {},

            {
                withCredentials: false,
                headers: {
                    "x-client-authority-proof": authorityProof,
                },
            },
        )

        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.API_CALL, {
            state: ApiCallStateEnum.SUCCESS,
            endpoint: "claimReward",
        })
        return res.data
    } catch (e) {
        const error = e as AxiosError
        AnalyticsUtils.publishAxiosError(error, "claimReward")
        throw e
    }
}

/**
 * The response of the get reward info request
 * @property total the total amount which have been claimed
 * @property arrived amout of actually paid-in
 * @property histories the reward history of the address
 * @property history.amount the amount of the reward
 * @property history.state the state of the reward
 * @property history.timestamp the timestamp of the reward
 * @property history.txID the txID of the reward
 */
type GetRewardsHistory = {
    page: number
    pageSize: number
    totalPages: number
} & RewardsHistory

/**
 * get the reward history for a specific address
 * @param address the address to get the reward history
 * @returns the reward history @GetRewardInfoHistory
 */
export const getRewardsHistory = async (
    address: string,
    page?: number,
    size?: number,
) => {
    try {
        const res = await axios.get<GetRewardsHistory>(`/${address}/history`, {
            params: {
                page,
                pageSize: size,
            },
        })

        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.API_CALL, {
            state: ApiCallStateEnum.SUCCESS,
            endpoint: "getRewardsHistory",
        })
        res.data.history = res.data.history.map(history => {
            if (!history.timestamp) history.timestamp = dayjs().unix()
            return history
        })
        return res.data
    } catch (e) {
        const error = e as AxiosError
        AnalyticsUtils.publishAxiosError(error, "getRewardsHistory")
        throw e
    }
}
