import { TokenParams, VotingTier } from "@/models"

const envRipeSeconds = import.meta.env.VITE_NODES_RIPE_SECONDS
// This is the minimum amount of VTHO that can be claimed
export const minClaimableVtho = 40

/**
 * Map rewards state coming from api to name of the state
 */
export const RewardsState: { [key: number]: string } = {
    0: "Pending",
    1: "Processing",
    2: "Success",
}

/**
 * Map strength level coming from contract to name of the level
 */

//This is the nft minted when the user apply for an economic node
export const NoneNodeStrengthLevel = "NONE"

//after this level the nfts are considered xNode
export const MinXNodeLevel = 4
export const EconomicNodeStrengthLevel: { [key: string]: string } = {
    "1": "Strength",
    "2": "Thunder",
    "3": "Mjolnir",
}

export const XNodeStrengthLevel: { [key: string]: string } = {
    "4": "VeThorX",
    "5": "StrengthX",
    "6": "ThunderX",
    "7": "MjolnirX",
}

export const allNodeStrengthLevel: { [key: string]: string } = {
    ...EconomicNodeStrengthLevel,
    ...XNodeStrengthLevel,
}

export const NodeParameters: Record<string, TokenParams> = {
    "0": {
        rewardRatio: "0",
        rewardRatioX: "0",
        ripeSeconds: "0",
        minBalance: "0",
    },
    "1": {
        rewardRatio: "100",
        rewardRatioX: "0",
        ripeSeconds: envRipeSeconds ?? (10 * 86400).toString(),
        minBalance: (1000000e18).toString(),
    },
    "2": {
        rewardRatio: "150",
        rewardRatioX: "0",
        ripeSeconds: envRipeSeconds ?? (20 * 86400).toString(),
        minBalance: (5000000e18).toString(),
    },
    "3": {
        rewardRatio: "200",
        rewardRatioX: "0",
        ripeSeconds: envRipeSeconds ?? (30 * 86400).toString(),
        minBalance: (15000000e18).toString(),
    },
    "4": {
        rewardRatio: "0",
        rewardRatioX: "25",
        ripeSeconds: "0",
        minBalance: (600000e18).toString(),
    },
    "5": {
        rewardRatio: "100",
        rewardRatioX: "100",
        ripeSeconds: envRipeSeconds ?? (30 * 86400).toString(),
        minBalance: (1600000e18).toString(),
    },
    "6": {
        rewardRatio: "150",
        rewardRatioX: "150",
        ripeSeconds: envRipeSeconds ?? (60 * 86400).toString(),
        minBalance: (5600000e18).toString(),
    },
    "7": {
        rewardRatio: "200",
        rewardRatioX: "200",
        ripeSeconds: envRipeSeconds ?? (90 * 86400).toString(),
        minBalance: (15600000e18).toString(),
    },
}

export const NodeStrengthLevel: { [key: string]: string } = {
    "0": NoneNodeStrengthLevel,
    ...EconomicNodeStrengthLevel,
    ...XNodeStrengthLevel,
}

/**
 * Map strength level coming from contract to hq image
 */
export const NodeStrengthLevelToImage: { [key: string]: string } = {
    "0": "/images/vnt/00-noNode.webp",
    "1": "/images/vnt/01-strength.webp",
    "2": "/images/vnt/02-thunder.webp",
    "3": "/images/vnt/03-mjolnir.webp",
    "4": "/images/vnt/04-vethorX.webp",
    "5": "/images/vnt/05-strengthX.webp",
    "6": "/images/vnt/06-thunderX.webp",
    "7": "/images/vnt/07-mjolnirX.webp",
}

export const DemoAddresses: { address: string; nodeType: string }[] = [
    {
        nodeType: "NONE",
        address: "0x4a880800A49A6E9521830BBDD539Fcd12a153865",
    },
    {
        nodeType: "NONE",
        address: "0x9B4f6Ea8BC220814782Cee0468144cA787A1DAC5",
    },
    {
        nodeType: "NONE",
        address: "0x9d5063e0c9E2Ae4ba4F62E220FAaB6e9df740E2C",
    },
    {
        nodeType: "Strength",
        address: "0x171979133c49AFcB06181E4B148637508AB7Db0c",
    },
    {
        nodeType: "Thunder",
        address: "0xb41e9b15941e9d7959611Da51920145E5011f904",
    },
    {
        nodeType: "Mjolnir",
        address: "0xEb0c565f69557481C6C7fA347CaE273128A0996E",
    },
    {
        nodeType: "VeThorX",
        address: "0xf84090b27109454feE78987ae123EC7AEe4c27aE",
    },
    {
        nodeType: "StrengthX",
        address: "0x2e9a643dAcE6210a8A9829BA53DbB0A7303C28b3",
    },
    {
        nodeType: "ThunderX",
        address: "0x6EfE0A37A13805C9317f445fb596478EDBb387bE",
    },
    {
        nodeType: "MjolnirX",
        address: "0xF5C03a961900Cc518447079962DabD634f6a7678",
    },
]

export const NodeVotingTier: Record<string, VotingTier> = {
    "0": {
        tier: "0",
        tierType: "NONE",
    },
    "1": {
        tier: "1",
        tierType: "EN",
    },
    "2": {
        tier: "5",
        tierType: "EN",
    },
    "3": {
        tier: "15",
        tierType: "EN",
    },
    "4": {
        tier: "1",
        tierType: "XN",
    },
    "5": {
        tier: "3",
        tierType: "XN",
    },
    "6": {
        tier: "10",
        tierType: "XN",
    },
    "7": {
        tier: "26",
        tierType: "XN",
    },
}
