import { useEffect, useState } from "react"

export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [])

    return {
        isMobile: width <= 768,
        isDesktop: width > 768,
    }
}
