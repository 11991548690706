import type { BaseTranslation } from "../i18n-types"

const en = {
    // TODO: your translations go here
    COMMON_LABEL_ACCOUNT: "Account",
    COMMON_LABEL_CONNECT: "Connect",
    COMMON_LABEL_CONNECTING: "Connecting...",
    COMMON_LABEL_DISCONNECT: "Disconnect",
    COMMON_LABEL_SOURCE: "Source",
    COMMON_LABEL_NETWORK: "Setting network",
    COMMON_LABEL_WALLET: "Wallet",
    COMMON_LABEL_CHANGE_THEME: "Change theme",
    COMMON_LABEL_SETTINGS: "Settings",
    COMMON_LABEL_AUCTION: "Auction",
    COMMON_LABEL_THEME: "Theme",
    COMMON_LABEL_CURRENCY: "Currency",
    COMMON_LABEL_NUMBER_FORMATTING: "Number format",
    COMMON_LABEL_DARK: "Dark",
    COMMON_LABEL_LIGHT: "Light",

    CONNECT_WALLET_WAITING_APPROVAL: "Waiting for wallet approval...",
    CONNECT_WALLET_CONNECT_WALLET: "Connect wallet",
    CONNECT_WALLET_CONNECTED_WALLET: "Connected wallet",

    COMMON_LABEL_AUCTION_ID: "Auction ID",
    AUCTION_BUY_BUTTON_TITLE: "Buy",
    AUCTION_VIEW_DETAILS_TITLE: "View Details",
    AUCTION_BUY_PRICE_PROGRESSION: "Price progression",
    COMMON_LABEL_AUCTION_TYPE: "Auction type",
    COMMON_LABEL_AUCTION_STARTING_PRICE: "Starting price",
    COMMON_LABEL_AUCTION_SELLER_ACCOUNT: "Seller",
    COMMON_LABEL_AUCTION_ENDING_PRICE: "Ending price",
    COMMON_LABEL_AUCTION_DURATION: "Duration",
    COMMON_LABEL_AUCTION_TOKEN_ID: "Token ID",
    COMMON_LABEL_AUCTION_AUCTION_ID: "Auction ID",
    COMMON_LABEL_AUCTION_TOKEN_LEVEL: "Token level",
    COMMON_LABEL_AUCTION_TOKEN_FIAT_PRICE: "Token fiat price",
    COMMON_LABEL_AUCTION_TOKEN_OWNER: "Token owner",
    COMMON_LABEL_AUCTION_STARTED_AT: "Auction started time",

    FIXED_PRICE_AUCTION_DESCRIPTION:
        "Establish a fixed sale price and valid duration for a straightforward and transparent selling process.",
    DUTCH_AUCTION_DESCRIPTION:
        "Define a starting price and a lowest price for your node. The price steadily decreases in a linear fashion every 5 minutes throughout the sale period.",
    PRICE_PROGRESSIVE_AUCTION_DESCRIPTION:
        "The price steadily decreases in a linear fashion every 5 minutes throughout the sale period.",
    DIRECT_SALE_GENERAL_DESCRIPTION:
        "Only the beneficiary can see the offer and the node transfer only occurs once the beneficiary accepts the offer.",
    DUTCH_AUCTION_GENERAL_DESCRIPTION:
        "A starting price and a lowest price are defined. The price steadily decreases in a linear fashion every 5 minutes throughout the sale period.",
    FIXED_PRICE_AUCTION_GENERAL_DESCRIPTION:
        "A fixed sale price and duration are defined. The node transfer occurs once the node is sold for the defined sale price. ",
} satisfies BaseTranslation

export default en
