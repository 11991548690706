import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { Currency, NumberFormat } from "@/models"
import { LocalStorageService } from "@/services"
import { DEFAULT_CURRENCY, DEFAULT_NUMBER_FORMAT } from "@/constants"

interface State {
    currency: Currency
    setCurrency: (currency: Currency) => void
    numberFormat: NumberFormat
    setNumberFormat: (numberFormat: NumberFormat) => void
}

export const useCurrencyStore = create<State>()(
    devtools(
        persist(
            (set, get) => ({
                currency: DEFAULT_CURRENCY,
                setCurrency: currency => {
                    set({ ...get(), currency })
                },
                numberFormat: DEFAULT_NUMBER_FORMAT,
                setNumberFormat: numberFormat => {
                    set({ ...get(), numberFormat })
                },
            }),
            {
                name: LocalStorageService.ACCOUNT_STORAGE_KEY,
            },
        ),
    ),
)
