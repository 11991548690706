import { cardAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(cardAnatomy.keys)

const boxShadow = defineStyle({
    _light: {
        boxShadow: "0px 0px 16px 0px rgba(11, 0, 67, 0.1)",
    },
    _dark: {
        boxShadow: "0px 0px 16px 0px rgba(150, 150, 150, 0.1)",
    },
})

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        ...boxShadow,
    },
    // header: {
    //     paddingBottom: "2px",
    // },
    // body: {
    //     paddingTop: "2px",
    // },
    // footer: {
    //     paddingTop: "2px",
    // },
})

const sizes = {
    // md: definePartsStyle({
    //     container: {
    //         borderRadius: "0px",
    //     },
    // }),
}

export const cardTheme = defineMultiStyleConfig({ baseStyle, sizes })
