import { useWalletConnect } from "@/contexts"
import { Account, WalletSource } from "@/models"
import { ConnexService } from "@/services"
import { SessionTypes } from "@walletconnect/types"
import { useCallback } from "react"
import { AddressUtils, ExtensionUtils } from "@/utils"
import { logger } from "@/logger"
import { useAccountStore } from "@/store"
const { getErrorMessage } = ExtensionUtils
const { getEnvNetwork } = ConnexService

/**
 * @function useWalletConnectionAdapter - Hook for connecting to a wallet automatically switching between WalletConnect and Connex
 * @category Hooks
 * @subcategory Wallet
 */
export const useWalletConnectionAdapter = () => {
    const { account } = useAccountStore()
    const {
        connect: connectToWalletConnect,
        identifyUser,
        session: walletConnectSession,
        signMessage: signMessageWithWalletConnect,
        signTx: signTxWithWalletConnect,
    } = useWalletConnect()

    /**
     * @function identifyWalletConnectUser - Identify a user using WalletConnect and return a certificate via callback
     * @param {SessionTypes.Struct} session - The WalletConnect session
     * @param {Function} onSuccess - Callback for when a user is identified
     * @param {Function} onError - Callback for when an error occurs
     * @returns {Promise<void>}
     */
    const identifyWalletConnectUser = useCallback(
        async (
            session: SessionTypes.Struct,
            onSuccess?: (address: string) => void,
            onError?: (err: unknown) => void,
        ) => {
            try {
                const namespace = session.namespaces["vechain"].accounts[0]
                if (!namespace)
                    throw new Error("WalletConnect namespace not found")
                const { address } =
                    AddressUtils.ParseWalletConnectNamespace(namespace)
                onSuccess?.(address)
            } catch (e: unknown) {
                const em = getErrorMessage(e)
                logger.error(em)
                onError?.(em)
            }
        },
        [identifyUser],
    )

    /**
     * @function connectToWallet - Connect to a wallet specified by the source
     * @param {WalletSource} source - The wallet type to connect to
     * @param {Function} onWalletConnectSuccess - Callback for when a wallet connect session is established
     * @param {Function} onConnexConnectSuccess - Callback for when a Connex wallet is connected
     * @param {Function} onError - Callback for when an error occurs
     * @returns {Promise<void>}
     * @example
     **/
    const connectToWallet = useCallback(
        async ({
            source,
            onSuccess,
            onError,
        }: {
            source: WalletSource
            onSuccess: (address: string) => void
            onError?: (err: unknown) => void
        }) => {
            try {
                if (source === WalletSource.WALLET_CONNECT) {
                    await connectToWalletConnect(session => {
                        identifyWalletConnectUser(session, onSuccess, onError)
                    })
                } else {
                    const cert =
                        await ConnexService.connectToWalletHandler(source)
                    onSuccess(cert.signer)
                }
            } catch (err) {
                onError?.(err)
            }
        },
        [connectToWalletConnect, identifyWalletConnectUser],
    )

    /**
     * @function signCertificate - Sign a certificate using the specified source
     * @param {WalletSource} source - The wallet type to connect to
     * @param {Connex.Vendor.CertMessage} message - The certificate message to sign
     * @param {string} address - Optional - Enforce user to sign with a specific address
     * @returns {Promise<Certificate>}
     */
    const signCertificate = useCallback(
        async (
            source: WalletSource,
            message: Connex.Vendor.CertMessage,
            address?: Account["address"],
        ) => {
            if (source === WalletSource.WALLET_CONNECT) {
                if (!walletConnectSession)
                    throw new Error("WalletConnect session not found")
                return await signMessageWithWalletConnect(
                    getEnvNetwork,
                    walletConnectSession,
                    message,
                )
            }

            return await ConnexService.signCertificate({
                source,
                message,
                address,
            })
        },
        [signMessageWithWalletConnect, walletConnectSession],
    )

    /**
     * @function signCertificate - Sign a certificate using the specified source
     * @param {WalletSource} source - The wallet type to connect to
     * @param {Connex.Vendor.CertMessage} message - The certificate message to sign
     * @param {string} address - Optional - Enforce user to sign with a specific address
     * @returns {Promise<Certificate>}
     */
    const signTransaction = useCallback(
        async (
            message: Connex.Vendor.TxMessage,
            options?: Connex.Driver.TxOptions,
        ) => {
            if (!account) throw new Error("No account found")
            const { source } = account
            if (source === WalletSource.WALLET_CONNECT) {
                if (!walletConnectSession)
                    throw new Error("WalletConnect session not found")
                return await signTxWithWalletConnect(
                    getEnvNetwork,
                    message,
                    options,
                )
            }

            return await ConnexService.requestTransaction({
                source,
                message,
                signer: account.address,
            })
        },
        [signTxWithWalletConnect, walletConnectSession, account],
    )

    return { connectToWallet, signCertificate, signTransaction }
}
