/**
 * Which wallet source is used to connect to the dapp
 */

import { IWalletSourceInfo, WalletSource } from "@/models"

const logosUrl = "/images/logo"
/**
 * Additional info about the wallet source to display in the UI
 */

export enum WalletSourceType {
    DESKTOP = "Desktop wallet",
    MOBILE = "Mobile wallet",
    BOTH = "Desktop and mobile wallet",
}

export const WalletSourceInfo: Record<WalletSource, IWalletSourceInfo> = {
    [WalletSource.VEWORLD]: {
        name: "VeWorld",
        key: "veworld",
        type: WalletSourceType.DESKTOP,
        lightLogo: `${logosUrl}/veWorld.png`,
        darkLogo: `${logosUrl}/veworld_black.png`,
        isAvailable: !!window.vechain,
        url: "https://veworld.com",
    },
    [WalletSource.WALLET_CONNECT]: {
        name: "VeWorld Mobile",
        key: "walletConnect",
        type: WalletSourceType.MOBILE,
        lightLogo: `${logosUrl}/veWorld.png`,
        darkLogo: `${logosUrl}/veworld_black.png`,
        isAvailable: true,
    },
    [WalletSource.SYNC2]: {
        name: "Sync2",
        key: "sync2",
        type: WalletSourceType.BOTH,
        lightLogo: `${logosUrl}/sync2.png`,
        darkLogo: `${logosUrl}/sync2.png`,
        url: "https://docs.vechain.org/sync2/get-started.html",
        isAvailable: true,
    },
}

export const DEFAULT_SOURCE = window.vechain
    ? WalletSource.VEWORLD
    : WalletSource.SYNC2
