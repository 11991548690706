/**
 * Possible sources of the connected wallet
 *
 */
export enum WalletSource {
    VEWORLD = "veworld",
    SYNC2 = "sync2",
    WALLET_CONNECT = "walletConnect",
}

/**
 * Additional info about the wallet source to display in the UI
 */
export interface IWalletSourceInfo {
    name: string
    key: string
    type: string
    isAvailable: boolean
    lightLogo?: string
    darkLogo?: string
    url?: string
}

/**
 * Representation of the connected account stored in local storage and used by the app
 */
export interface Account {
    address: string
    source: WalletSource
}
