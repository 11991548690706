import { HStack, Skeleton, Text } from "@chakra-ui/react"
import { VechainPictogram } from ".."
import { useAccountBalance } from "@/api/hooks"
import { useBalance } from "@/hooks"
import { VET } from "@/constants"

type Props = {
    address: string
    iconSize?: number
    balanceColor?: string
}
export const CurrentBalance: React.FC<Props> = ({ address, balanceColor }) => {
    const {
        data: accountBalance,
        isLoading,
        fetchStatus,
    } = useAccountBalance(address)
    const isAccountBalanceIdle = fetchStatus === "idle"

    const { formattedBalance: formattedAccountBalance } = useBalance({
        balance: accountBalance?.balance ?? "0",
        token: VET,
        includeDecimals: true,
    })

    return (
        <Skeleton
            data-cy="current-balance-skeleton"
            mt={0.5}
            isLoaded={!isLoading}
            speed={isAccountBalanceIdle ? 0 : 0.8}>
            <HStack spacing={0}>
                <Text fontSize={"sm"} fontWeight={"medium"}>
                    {"Current balance:"}
                </Text>
                <Text
                    data-cy="formatted-account-balance"
                    fontWeight={"semibold"}
                    fontSize="sm"
                    ml={1}
                    {...(balanceColor && {
                        color: balanceColor,
                    })}>
                    {formattedAccountBalance}
                </Text>
                <VechainPictogram boxSize={6} />
            </HStack>
        </Skeleton>
    )
}
