import { abi } from "thor-devkit"

export const getCurrentPrice: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "getCurrentPrice",
    outputs: [
        {
            name: "",
            type: "uint256",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const hasWhiteList: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "hasWhiteList",
    outputs: [
        {
            name: "",
            type: "bool",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}

export const getAuction: abi.Function.Definition = {
    constant: true,
    inputs: [
        {
            name: "_tokenId",
            type: "uint256",
        },
    ],
    name: "getAuction",
    outputs: [
        {
            name: "autionId",
            type: "uint256",
        },
        {
            name: "seller",
            type: "address",
        },
        {
            name: "startingPrice",
            type: "uint256",
        },
        {
            name: "endingPrice",
            type: "uint256",
        },
        {
            name: "duration",
            type: "uint64",
        },
        {
            name: "startedAt",
            type: "uint64",
        },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
}
