import { Connex } from "@vechain/connex"
import { Certificate } from "thor-devkit"
import { Account, NETWORK_TYPE, Network, WalletSource } from "@/models"
import { defaultMainNetwork, defaultSoloNetwork } from "@/constants"
import { logger } from "@/logger"

//The network requested by the env variables

export const getEnvNetwork: Network =
    import.meta.env.VITE_NETWORK_TYPE === "solo"
        ? defaultSoloNetwork
        : defaultMainNetwork

const getConnexNetwork = (network: Network) => {
    if (network.type === NETWORK_TYPE.MAIN) return "main"
    if (network.type === NETWORK_TYPE.TEST) return "test"
    return network.genesis
}

const connexInstance: {
    withExtension: Connex | undefined
    noExtension: Connex | undefined
} = {
    withExtension: undefined,
    noExtension: undefined,
}
export const getConnexInstance = (walletSource?: WalletSource): Connex => {
    const type =
        walletSource !== WalletSource.VEWORLD ? "noExtension" : "withExtension"

    if (connexInstance[type]) {
        return <Connex>connexInstance[type]
    } else {
        connexInstance[type] = new Connex({
            node: getEnvNetwork.currentUrl,
            network: getConnexNetwork(getEnvNetwork),
            noExtension: type === "noExtension",
        })
        return <Connex>connexInstance[type]
    }
}

/**
 *  Request the wallet to sign a certificate to prove the identity of the user
 * @param source Wallet source
 * @param network Network to use
 * @returns
 */
export const connectToWalletHandler = async (
    source: WalletSource,
): Promise<Certificate> => {
    const message: Connex.Vendor.CertMessage = {
        purpose: "identification",
        payload: {
            type: "text",
            content: `Signing this certificate, you accept this dApp terms of service available at https://${window.location.host}/rewards_tos.pdf`,
        },
    }

    return await signCertificate({ source, message })
}

/**
 *  Request the wallet to sign a certificate to prove the identity of the user
 * This is used in order to authenticate the claim api and to connect to the wallet
 * @param source Wallet source
 * @param network Network to use
 * @param message Message to sign by the wallet
 * @param address - Optional - Enforce user to sign with a specific address
 * @returns
 */
export const signCertificate = async ({
    source,
    message,
    address,
}: {
    source: WalletSource
    message: Connex.Vendor.CertMessage
    address?: Account["address"]
}): Promise<Certificate> => {
    const connex = getConnexInstance(source)

    const certRequest = connex.vendor.sign("cert", message)
    const certResponse = address
        ? await certRequest.signer(address).request()
        : await certRequest.request()

    const cert: Certificate = {
        purpose: message.purpose,
        payload: message.payload,
        domain: certResponse.annex.domain,
        timestamp: certResponse.annex.timestamp,
        signer: certResponse.annex.signer,
        signature: certResponse.signature,
    }

    if (!cert) throw new Error("No cert returned")

    logger.debug("Signed cert", cert)
    Certificate.verify(cert)
    logger.info("Cert verified")

    return cert
}

export const getAccount = async (
    accountAddress?: string,
): Promise<Connex.Thor.Account> => {
    logger.debug("Getting account", accountAddress)
    const connex = getConnexInstance()
    if (!accountAddress) throw new Error("No account address provided")
    return await getAccountEx(connex, accountAddress)
}

export const getAccountEx = async (connex: Connex, accountAddress: string) => {
    return await connex.thor.account(accountAddress).get()
}

/**
 *  Sign a transaction using the specified source and network
 * @param param0  Sign a transaction using the specified source and network
 * @returns  Transaction id
 */
export const requestTransaction = async ({
    source,
    signer,
    message,
    comment,
    delegateUrl,
}: {
    source: WalletSource
    signer: string
    message: Connex.Vendor.TxMessage
    comment?: string
    delegateUrl?: string
}) => {
    const connex = getConnexInstance(source)
    const request = connex.vendor
        .sign("tx", message)
        .signer(signer)
        .link(window.location.href + "#/tx-callback/{txid}")

    if (comment) request.comment(comment)
    if (delegateUrl) request.delegate(delegateUrl, signer)
    return await request.request()
}

/**
 * Poll the chain for a transaction receipt until it is found (or timeout after 3 blocks)
 * @param id Transaction id
 * @param blocksTimeout Number of blocks to wait before timeout
 * @returns  Transaction receipt
 */
export const pollForReceipt = async (
    id?: string,
    blocksTimeout = 3,
): Promise<Connex.Thor.Transaction.Receipt> => {
    if (!id) throw new Error("No transaction id provided")

    const thor = getConnexInstance().thor

    const transaction = thor.transaction(id)
    let receipt

    //Query the transaction until it has a receipt
    //Timeout after 3 blocks
    for (let i = 0; i < blocksTimeout; i++) {
        receipt = await transaction.getReceipt()
        if (receipt) {
            break
        }
        await thor.ticker().next()
    }

    if (!receipt) {
        throw new Error("Transaction receipt not found")
    }

    const transactionData = await transaction.get()

    if (!transactionData) throw Error("Failed to get TX data")

    return receipt
}

/**
 *  Query the chain for the revert reason of a transaction
 * @param id   Transaction id
 * @returns   Revert reason
 */
export const explainRevertReason = async (id: string): Promise<string> => {
    const thor = getConnexInstance().thor

    const tx = thor.transaction(id)
    const receipt = await tx.getReceipt()
    const transactionData = await tx.get()

    if (receipt && transactionData) {
        const explainedTransaction = await thor
            .explain(transactionData?.clauses)
            .caller(transactionData?.origin)
            .execute()

        if (receipt.reverted) {
            return explainedTransaction
                .map(({ revertReason }) => revertReason)
                .join(" ,")
        }
    }

    return "No revert reason found"
}

/**
 * Get the block details for a block id
 * @param id  Block id
 * @returns  Block details
 */
export const getBlock = async (id: string) => {
    const connex = getConnexInstance()
    return await connex.thor.block(id).get()
}

/**
 * Get the transactions details for a transaction id
 * @param id  Block id
 * @returns  Block details
 */
export const getTransaction = async (id: string) => {
    const connex = getConnexInstance()
    return await connex.thor.transaction(id).get()
}

/**
 * Execute specific contract ABI
 * @param connex initialised connex object
 * @param account account address to call
 * @param methodAbi method's abi spec
 * @param args parameters to be passed to contract call
 * @throws if vmError is set in result
 */
export const connexCall = async <Kind>(
    connex: Connex,
    account: string,
    methodAbi: object,
    ...args: Kind[]
) => {
    const result = await connex.thor
        .account(account)
        .method(methodAbi)
        .call(...args)
    if (result.vmError) throw new Error(result.vmError)
    return result
}
