import {
    getTokenForAddressQueryKey,
    getTokenMetadataQueryKey,
} from "@/api/hooks"
import { TokenAuctionService } from "@/services"
import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useSendTransaction } from "../useSendTransaction"
import { AnalyticsUtils } from "@/utils"
import { AnalyticsEventsEnum } from "@/constants"
import { useAccountStore } from "@/store"
import { useCallback } from "react"

type UseApplyUpgradeProps = {
    tokenId?: string
    toLevel?: string
    onSuccess?: () => void
    invalidateCache?: boolean
    onSuccessMessageTitle?: string
}
/**
 * Hook to apply for a token upgrade
 * This hook will run the upgrade and wait for the txReceipt
 * @param tokenId the token id to apply for upgrade
 * @param nextLevel the next level to upgrade to
 * @param onSuccess callback to run when the upgrade is successful
 * @param invalidateCahce boolean to indicate if the related react-query cache should be updated (default: true)
 * @param onSuccessMessageTitle the title of the success toast message
 * @returns sendTransaction function to send the tx
 * @returns sendTransactionLoading boolean to indicate if the tx is waiting for confirmation
 * @returns sendTransactionError boolean to indicate if the upgrade has failed
 * @returns isTxReceiptLoading boolean to indicate if the tx receipt is loading
 * @returns isTxReceiptError boolean to indicate if the tx receipt has failed
 * @returns txReceipt the tx receipt
 */
export const useApplyUpgrade = ({
    tokenId,
    toLevel,
    onSuccess,
    invalidateCache = true,
    onSuccessMessageTitle = "Upgrade requested successfully.",
}: UseApplyUpgradeProps) => {
    const toast = useToast()
    const queryClient = useQueryClient()
    const { account } = useAccountStore()

    const buildClauses = useCallback(() => {
        if (!toLevel) throw new Error("toLevel is required")
        const clauses = TokenAuctionService.buildApplyUpgradeTx(toLevel)
        return [clauses]
    }, [toLevel])

    const handleOnSuccess = useCallback(async () => {
        if (invalidateCache) {
            await queryClient.invalidateQueries({
                queryKey: getTokenForAddressQueryKey(account?.address ?? ""),
                type: "all",
            })
            await queryClient.invalidateQueries({
                queryKey: getTokenMetadataQueryKey(tokenId ?? "0"),
                type: "all",
            })
        }
        AnalyticsUtils.trackEvent(
            AnalyticsEventsEnum.APPLY_UPGRADE_SUCCESSFUL,
            {
                walletSource: account?.source,
            },
        )
        toast({
            title: onSuccessMessageTitle,
            description: `You can follow the progress in the dashboard`,
            status: "success",
            position: "bottom-left",
            duration: 5000,
            isClosable: true,
        })
        onSuccess?.()
    }, [
        invalidateCache,
        queryClient,
        tokenId,
        toast,
        onSuccess,
        account?.source,
    ])

    const result = useSendTransaction({
        clauses: buildClauses,
        onSuccess: handleOnSuccess,
    })

    return result
}
