import TypesafeI18n from "@/i18n/i18n-react"
import { detectLocale } from "@/i18n/i18n-util"
import { loadLocale } from "@/i18n/i18n-util.sync"
import React, { useEffect, useState } from "react"
import { navigatorDetector } from "typesafe-i18n/detectors"

type Props = {
    children: React.ReactNode
}

export const TranslationProvider = ({ children }: Props) => {
    // Detect locale
    // (Use as advanaced locale detection strategy as you like.
    // More info: https://github.com/ivanhofer/typesafe-i18n/tree/main/packages/detectors)
    const locale = detectLocale(navigatorDetector)

    // Load locales
    // (Use a data fetching solution that you prefer)
    const [localesLoaded, setLocalesLoaded] = useState(false)
    useEffect(() => {
        loadLocale(locale)
        setLocalesLoaded(true)
    }, [locale])

    if (!localesLoaded) {
        return null
    }
    return <TypesafeI18n locale={locale}>{children}</TypesafeI18n>
}
