import { createBrowserRouter, Navigate, Outlet } from "react-router-dom"
import { ROUTES } from "."
import { RouteErrorBoundary } from "@/components"
import React from "react"

/* eslint-disable react-refresh/only-export-components */
const Dashboard = React.lazy(() => import("@/pages/Dashboard"))
const Marketplace = React.lazy(() => import("@/pages/Marketplace"))
const ConnectWallet = React.lazy(() => import("@/pages/ConnectWallet"))
const Settings = React.lazy(() => import("@/pages/Settings"))
const NavBar = React.lazy(
    () => import("@/components/common/layout/NavBar/Navbar"),
)

export const router = createBrowserRouter([
    {
        errorElement: <RouteErrorBoundary />,
        element: (
            <>
                <NavBar />
                <Outlet />
            </>
        ),
        children: [
            {
                path: "/",
                element: <Navigate to={ROUTES.DASHBOARD} replace={true} />,
            },
            {
                path: ROUTES.MARKETPLACE,
                element: (
                    <React.Suspense fallback={<></>}>
                        <Marketplace />
                    </React.Suspense>
                ),
            },
            {
                path: ROUTES.CONNECT_WALLET,
                element: (
                    <React.Suspense fallback={<></>}>
                        <ConnectWallet />
                    </React.Suspense>
                ),
            },
            {
                path: ROUTES.DASHBOARD,
                element: (
                    <React.Suspense fallback={<></>}>
                        <Dashboard />
                    </React.Suspense>
                ),
            },
            {
                path: ROUTES.SETTINGS,
                element: (
                    <React.Suspense fallback={<></>}>
                        <Settings />
                    </React.Suspense>
                ),
            },
        ],
    },
])
