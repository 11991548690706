import { FungibleToken } from "@/models"
import { useCurrencyStore } from "@/store"
import { FormattingUtils } from "@/utils"

const { humanNumber, convertToFiatBalance, scaleNumberDown } = FormattingUtils

type Props = {
    balance: string
    exchangeRate?: number
    token: FungibleToken
    includeDecimals?: boolean
    numberFormat?: string
}

/**
 *
 * @param balance the balance to format
 * @param exchangeRate the exchange rate to use for calculating the fiat balance
 * @param token the token decimals to use for the balance if scaleNumberDown is needed
 * @param numberFormat number formatting string locale
 * @param includeDecimals if scaleNumberDown before formatting the number. Use this if the balance comes from the scmart contract
 * @returns the formatted balance, fiat balance and human fiat balance
 */
export const useBalance = ({
    balance,
    exchangeRate,
    token,
    numberFormat,
    includeDecimals = false,
}: Props) => {
    const { numberFormat: userNumberFormat } = useCurrencyStore()
    numberFormat = numberFormat ?? userNumberFormat.locale

    // If number includes decimals, i have to scale it down before formatting it (typically when the balance comes from the smart contract)
    const parsedBalance = includeDecimals
        ? scaleNumberDown(balance, token.decimals, 0)
        : balance
    const formattedBalance = humanNumber(
        parsedBalance,
        parsedBalance,
        false,
        numberFormat,
    )

    const formattedFiatBalance = convertToFiatBalance(
        parsedBalance,
        exchangeRate ?? 0,
        0,
    )

    const humanFiatBalance = humanNumber(
        formattedFiatBalance,
        formattedFiatBalance,
        true,
        numberFormat,
    )

    return {
        parsedBalance,
        formattedBalance,
        formattedFiatBalance,
        humanFiatBalance,
    }
}
