import mixpanel from "mixpanel-browser"
import * as uuid from "uuid"
import { logger } from "@/logger"
import { AxiosError } from "axios"
import {
    AnalyticsEventsEnum,
    ApiCallStateEnum,
    ApiErrorClassification,
} from "@/constants"

const MIX_PANEL_TOKEN = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN
let isInitialized = false

export interface Properties {
    [key: string]: unknown
}

export const initialise = () => {
    if (MIX_PANEL_TOKEN) {
        mixpanel.init(MIX_PANEL_TOKEN, {
            // debug: true,
            track_pageview: true,
            ignore_dnt: true,
        })
        mixpanel.identify(uuid.v4())

        isInitialized = true
        logger.info("Mixpanel initialized")
    } else {
        logger.warn("Analytics not enabled")
    }
}

export const trackEvent = (event: string, properties?: Properties): void => {
    try {
        // const securitySettings = getSecuritySettings(getState())

        if (isInitialized) {
            mixpanel.track(event, properties)
        } else {
            logger.warn("Analytics not initialized or enabled")
        }
    } catch (e) {
        logger.warn("Error tracking event", e)
    }
}

export const publishAxiosError = (e: AxiosError, endpoint: string) => {
    let classification = ApiErrorClassification.UNKNOWN
    let statusCode
    if (e.response) {
        // request made api and responded with a status code
        classification = ApiErrorClassification.RESPONDED
        statusCode = e.response.status
    } else if (e.request) {
        // request made, no response
        classification = ApiErrorClassification.NO_RESPONSE
        statusCode = undefined
    } else {
        // unknown error
        classification = ApiErrorClassification.UNKNOWN
        statusCode = undefined
    }
    trackEvent(AnalyticsEventsEnum.API_CALL, {
        state: ApiCallStateEnum.ERROR,
        classification,
        error: e.toJSON(),
        endpoint,
        statusCode,
    })
}

export default {
    publishAxiosError,
    initialise,
    trackEvent,
}
