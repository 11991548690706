import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { LocalStorageService } from "@/services"

interface State {
    enableRewardsAccount: boolean
    setEnableRewardsAccount: (enableRewardsAccount: boolean) => void
}

export const useEnableRewardsAccount = create<State>()(
    devtools(
        persist(
            (set, get) => ({
                enableRewardsAccount: false, //false by default
                setEnableRewardsAccount: enableRewardsAccount => {
                    set({ ...get(), enableRewardsAccount })
                },
            }),
            {
                name: LocalStorageService.ACCOUNT_STORAGE_KEY,
            },
        ),
    ),
)
