import {
    HTMLChakraProps,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    ResponsiveValue,
} from "@chakra-ui/react"
import React from "react"

interface IBaseModal extends Omit<ModalProps, "children"> {
    isOpen: boolean
    onClose: () => void
    header?: React.ReactNode
    headerStyle?: HTMLChakraProps<"header">
    body?: React.ReactNode
    footer?: React.ReactNode
    showCloseButton?: boolean
    closeButtonStyle?: HTMLChakraProps<"button">
    size?: ResponsiveValue<
        "xs" | "sm" | "md" | "lg" | "xl" | "full" | "2xl" | "3xl" | "4xl"
    >
}
export const BaseModal: React.FC<IBaseModal> = ({
    isOpen,
    onClose,
    header,
    headerStyle = {},
    body,
    footer,
    showCloseButton = true,
    closeButtonStyle = {},
    size,
    ...modalProps
}) => {
    return (
        <Modal
            trapFocus={true}
            isOpen={isOpen}
            onClose={onClose}
            size={size}
            {...modalProps}>
            <ModalOverlay />
            <ModalContent pb={2}>
                {header && <ModalHeader {...headerStyle}>{header}</ModalHeader>}
                {showCloseButton && (
                    <ModalCloseButton
                        data-cy="modal-close-button"
                        {...closeButtonStyle}
                    />
                )}
                {body && <ModalBody>{body}</ModalBody>}
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </ModalContent>
        </Modal>
    )
}
