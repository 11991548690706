import { AuctionDuration } from "@/models"

export const durationOptions = [
    {
        label: "2h",
        value: AuctionDuration.TWO_HOURS,
    },
    {
        label: "4h",
        value: AuctionDuration.FOUR_HOURS,
    },
    {
        label: "6h",
        value: AuctionDuration.SIX_HOURS,
    },
    {
        label: "12h",
        value: AuctionDuration.TWELVE_HOURS,
    },
    {
        label: "1d",
        value: AuctionDuration.ONE_DAY,
    },
    {
        label: "3d",
        value: AuctionDuration.THREE_DAYS,
    },
    {
        label: "1w",
        value: AuctionDuration.ONE_WEEK,
    },
]
