import { TokenAuctionABI, VNT_MAIN_CONTRACT } from "@/constants"
import { dayjs } from "@/utils/DatetimeUtils/DatetimeUtils.ts"
import { AuctionWithMetadata } from "@/models/Auction.ts"
import ConnexService from "../ConnexService"
import {
    AddAuctionWhiteListEvent,
    ApplyUpgradeEvent,
    AuctionCancelledEvent,
    AuctionCreatedEvent,
    AuctionSuccessfulEvent,
    CancelUpgradeEvent,
    CompleteTokenDetails,
    LevelChangedEvent,
    TokenMetadata,
    TokenParams,
} from "@/models"
import { abi } from "thor-devkit"
import { scaleNumberUp } from "@/utils/FormattingUtils/FormattingUtils"
import { getTokenAuction } from "@/services/ClockAuctionService/ClockAuctionService"
import BigNumber from "bignumber.js"
import { CannotTransferTokenResponse } from "@/utils/RewardsUtils/RewardsUtils"

const connex = ConnexService.getConnexInstance()
/**
 *  Get the total supply of VNT tokens
 * @rejects in case of VM error
 * @returns the total supply of VNT tokens
 */
export const totalSupply = async (): Promise<number> => {
    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.totalSupply)
        .call()

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return res.decoded[0]
}

/**
 * Obtains token transfer cooldown contract parameter in milliseconds
 */
export const getTransferCooldownMs = async () => {
    const res = await ConnexService.connexCall(
        connex,
        VNT_MAIN_CONTRACT,
        TokenAuctionABI.functions.transferCooldown,
    )
    return new BigNumber(res.decoded[0], 10).multipliedBy(1_000, 10).toNumber()
}

/**
 *  Check if a token can be transferred to a specific address
 * @param tokenDetails  the token details to check if can be transferred
 * @param targetAddress  the address to transfer the token to
 * @returns  the response of the check
 */
export async function checkTransferReceiver(
    tokenDetails: CompleteTokenDetails,
    targetAddress: string,
): Promise<CannotTransferTokenResponse> {
    const { id, params } = tokenDetails

    const accountInfo = await ConnexService.getAccount(targetAddress)
    if (accountInfo.hasCode)
        return {
            key: "targetIsContract",
            kind: "error",
            message: `The recipient is a contract`,
        }

    const hasToken = (await balanceOf(targetAddress)) > 0
    if (hasToken)
        return {
            key: "alreadyOwnToken",
            kind: "error",
            message: `The recipient already has a token`,
        }

    // call the contract method to check if the token can be transferred
    const res = await ConnexService.connexCall(
        connex,
        VNT_MAIN_CONTRACT,
        TokenAuctionABI.functions.canTransfer,
        id,
    )
    const canBeTransferred: boolean = res.decoded[0]

    if (!canBeTransferred)
        return {
            key: "unknown",
            kind: "error",
            message: `Unable to transfer this token for unknown reason`,
        }

    const isMeetingBalanceRequirement = BigNumber(
        accountInfo.balance,
    ).isGreaterThanOrEqualTo(BigNumber(params.minBalance))

    if (!isMeetingBalanceRequirement)
        return {
            key: "balanceRequirement",
            kind: "warning",
            message: `The recipient lacks the necessary balance for your node`,
        }

    return null
}

export const buildTransferClause = (
    address: string,
    tokenId: string,
): Connex.Vendor.TxMessage[0] => {
    const txClause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.transfer)
        .asClause(address, tokenId)

    return {
        ...txClause,
        comment: `Please confirm transferring your Rewards token(${tokenId}) to ${address}`,
        abi: TokenAuctionABI.functions.transfer,
    }
}

/**
 *  Check if an address is a contract operator
 * @param address  the address to check if is an operator
 * @rejects in case of VM error
 * @returns true if the address is an operator, false otherwise
 */
export const isOperator = async (address: string): Promise<number> => {
    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.operators)
        .call(address)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return res.decoded[0]
}

/**
 *  Get the total amount of x tokens
 * @rejects in case of VM error
 * @returns the total amount of x tokens
 */
export const xTokenCount = async (): Promise<number> => {
    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.xTokenCount)
        .call()

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return res.decoded[0]
}

/**
 *  Get AuctionCreated events from blockchain
 * @rejects in case of VM error
 * @returns the auction created events
 */
export const getAllAuctionEvents: (
    from?: number,
    offset?: number,
    limit?: number,
) => Promise<Connex.Thor.Filter.Row<"event">[]> = async (
    from = 0,
    offset = 0,
    limit = 256,
) => {
    const events = await getEvents({ offset, limit, from })

    if (events.length >= limit) {
        offset = offset + limit
        return events.concat(await getAllAuctionEvents(from, offset))
    } else {
        return events
    }
}

/**
 * Get events from blockchain (auction created, auction successful, auction cancelled)
 * @param order
 * @param offset
 * @param limit
 * @param from block parse start from
 */
export const getEvents = async ({
    order = "asc",
    offset = 0,
    limit = 256,
    from = 0,
}: GetEventsProps): Promise<Connex.Thor.Filter.Row<"event">[]> => {
    return await connex.thor
        .filter("event", [
            {
                address: VNT_MAIN_CONTRACT,
                topic0: new abi.Event(TokenAuctionABI.events.AuctionCreated)
                    .signature,
            },
            {
                address: VNT_MAIN_CONTRACT,
                topic0: new abi.Event(TokenAuctionABI.events.AuctionSuccessful)
                    .signature,
            },
            {
                address: VNT_MAIN_CONTRACT,
                topic0: new abi.Event(TokenAuctionABI.events.AuctionCancelled)
                    .signature,
            },
        ])
        .range({
            from,
            to: connex.thor.status.head.number,
            unit: "block",
        })
        .order(order)
        .apply(offset, limit)
}

/**
 * Get events from blockchain by encoded events
 * @param order
 * @param offset
 * @param limit
 * @param from block parse start from
 * @param topics encoded event topics
 */
async function queryNodeEvents({
    order = "asc",
    offset = 0,
    limit = 256,
    from = 0,
    topics = [],
}: GetEventsProps) {
    return await connex.thor
        .filter("event", [
            {
                address: VNT_MAIN_CONTRACT,
                topic0: topics[0] || undefined,
                topic1: topics[1] || undefined,
                topic2: topics[2] || undefined,
                topic3: topics[3] || undefined,
                topic4: topics[4] || undefined,
            },
        ])
        .range({
            from,
            to: connex.thor.status.head.number,
            unit: "block",
        })
        .order(order)
        .apply(offset, limit)
}

/**
 *  Get AuctionCreated events from blockchain
 * @param seller the seller of an auction lot
 * @rejects in case of VM error
 * @returns the auction created events
 */
export const getSuccessfulAuctionBySeller = async (
    seller: string | undefined,
): Promise<AuctionSuccessfulEvent | null> => {
    if (!seller) return null
    const weekAgoTimestamp = dayjs().subtract(7, "day").unix()
    const from = await getBlockNumberByTimestamp(weekAgoTimestamp)
    const auctionSuccessfulEvent = new abi.Event(
        TokenAuctionABI.events.AuctionSuccessful,
    )
    const topics = auctionSuccessfulEvent.encode({ _seller: seller })
    const events = await queryNodeEvents({
        topics,
        from,
        limit: 1,
        order: "desc",
    })

    if (events.length === 0) return null

    const decoded = auctionSuccessfulEvent.decode(
        events[0].data,
        events[0].topics,
    )
    return {
        auctionId: decoded._auctionId,
        tokenId: decoded._tokenId,
        seller: decoded._seller,
        winner: decoded._winner,
        finalPrice: decoded._finalPrice,
        meta: events[0].meta,
    }
}

/**
 *  Get auctions from blockchain
 * @rejects in case of VM error
 * @returns the auction objects for last 7 days
 */
export const getActiveAuctions = async (): Promise<AuctionWithMetadata[]> => {
    const weekAgoTimestamp = dayjs().subtract(7, "day").unix()
    const weekAgoBlock = await getBlockNumberByTimestamp(weekAgoTimestamp)

    const auctionCreatedEvents: AuctionCreatedEvent[] = []
    const auctionSuccessfulEvents: AuctionSuccessfulEvent[] = []
    const auctionCancelledEvents: AuctionCancelledEvent[] = []

    const auctionCreatedEvent = new abi.Event(
        TokenAuctionABI.events.AuctionCreated,
    )
    const auctionSuccessfulEvent = new abi.Event(
        TokenAuctionABI.events.AuctionSuccessful,
    )
    const auctionCancelledEvent = new abi.Event(
        TokenAuctionABI.events.AuctionCancelled,
    )

    // Get all events from the last 7 days
    const pastEvents = await getAllAuctionEvents(weekAgoBlock)

    pastEvents.forEach(event => {
        if (event.topics[0] == auctionCreatedEvent.signature) {
            const {
                _auctionId,
                _tokenId,
                _startingPrice,
                _endingPrice,
                _duration,
            } = auctionCreatedEvent.decode(event.data, event.topics)

            auctionCreatedEvents.push({
                auctionId: _auctionId,
                tokenId: _tokenId,
                startingPrice: _startingPrice,
                endingPrice: _endingPrice,
                duration: _duration,
                meta: event.meta,
            })
        } else if (event.topics[0] == auctionSuccessfulEvent.signature) {
            const { _auctionId, _tokenId, _seller, _winner, _finalPrice } =
                auctionSuccessfulEvent.decode(event.data, event.topics)

            auctionSuccessfulEvents.push({
                auctionId: _auctionId,
                tokenId: _tokenId,
                seller: _seller,
                winner: _winner,
                finalPrice: _finalPrice,
                meta: event.meta,
            })
        } else if (event.topics[0] == auctionCancelledEvent.signature) {
            const { _auctionId, _tokenId } = auctionCancelledEvent.decode(
                event.data,
                event.topics,
            )
            auctionCancelledEvents.push({
                auctionId: _auctionId,
                tokenId: _tokenId,
                meta: event.meta,
            })
        }
    })

    // Filter out auctions that are not active anymore
    const activeAuctions = auctionCreatedEvents.filter(
        event =>
            auctionSuccessfulEvents.every(
                el => el.auctionId !== event.auctionId,
            ) &&
            auctionCancelledEvents.every(
                el => el.auctionId !== event.auctionId,
            ) &&
            event.meta.blockTimestamp + parseInt(event.duration) >
                dayjs().unix(),
    )

    const currentAuctions = await Promise.all(
        activeAuctions.map(event => getTokenAuction(event.tokenId)),
    )

    return currentAuctions.filter(
        auction => auction.tokenMetadata?.onAuction,
    ) as AuctionWithMetadata[]
}

/**
 *  Get block number by timestamp
 * @param ts  the timestamp to get the block number
 * @returns block number
 */
export const getBlockNumberByTimestamp = async (
    ts: number,
): Promise<number> => {
    if (connex.thor.status.progress < 1) {
        await _delay(1000)
        return await getBlockNumberByTimestamp(ts)
    }
    let { timestamp, number } = connex.thor.status.head
    let diff = Math.floor((timestamp - ts) / 10)

    if (diff < 0) {
        throw new Error(
            `Timestamp Error: BestBlock=${timestamp}, BestNumber: ${number} Start=${ts}`,
        )
    }

    // If node head block is less than 7 days ago, return beginning block
    if (number - diff < 0) {
        return 1
    }

    let i = 0
    // If the interval between two blocks is 20 s and the target time is in the middle of the two, the following
    // block --------- target time --------- block
    //  |------ 10s ------|------ 10s ------|
    // diff will loop infinitely between -1 and 1 without converging
    // At this point, both blocks before and after the target time are acceptable
    // Up to 10 rounds of judgment
    while (diff != 0 && i < 10) {
        const resp = await connex.thor.block(number - diff).get()
        timestamp = resp?.timestamp || 0
        number = resp?.number || 0
        diff = Math.floor((timestamp - ts) / 10)
        i++
    }

    return number
}

/**
 *  Get the total amount of normal tokens (economic nodes)
 * @rejects in case of VM error
 * @returns the total amount of normal tokens
 */
export const normalTokenCount = async (): Promise<number> => {
    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.normalTokenCount)
        .call()

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return res.decoded[0]
}

/**
 *  Get the VNT balance of a specific address (max 1)
 * @param address  the address to get the balance
 * @rejects in case of VM error
 * @returns the balance
 */
export const balanceOf = async (address: string): Promise<number> => {
    const res = await ConnexService.connexCall<string>(
        connex,
        VNT_MAIN_CONTRACT,
        TokenAuctionABI.functions.balanceOf,
        address,
    )

    return res.decoded[0]
}

/**
 *  Get the token id of the VNT token for a specific address (max 1) address is required (optional for better DX with react-query)
 * @param address the address to get the token id
 * @rejects in case of VM error
 * @returns the token id
 */
export const ownerToId = async (address?: string): Promise<string> => {
    if (!address) return Promise.reject(new Error("address is required"))
    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.ownerToId)
        .call(address)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    return res.decoded[0]
}

/**
 *  Get the metadata of a specific token id. tokenId is required (optional for better DX with react-query)
 * @see Metadata
 * @param tokenId the token id to get the metadata
 * @rejects in case of VM error
 * @returns the metadata
 */
export const getMetadata = async (tokenId?: string): Promise<TokenMetadata> => {
    if (!tokenId) return Promise.reject(new Error("tokenId is required"))

    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.getMetadata)
        .call(tokenId)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    const ts = new Date(
        // in solidity timestamp is in seconds, in JS it's milliseconds
        new BigNumber(res.decoded[4], 10).multipliedBy(1_000, 10).toNumber(),
    )

    return {
        owner: res.decoded[0],
        level: res.decoded[1],
        onUpgrade: res.decoded[2],
        onAuction: res.decoded[3],
        lastTransferTime: res.decoded[4],
        lastTransferDateTime: ts,
        createdAt: res.decoded[5],
        updatedAt: res.decoded[6],
    }
}

/**
 *  Get the params of a specific token id. Node level is required (optional for better DX with react-query)
 * @param tokenId  the token id to get the params
 * @rejects in case of VM error
 * @returns  the params
 */
export const getTokenParams = async (
    nodeLevel?: string,
): Promise<TokenParams> => {
    if (!nodeLevel) return Promise.reject(new Error("nodeLevel is required"))

    const res = await connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.getTokenParams)
        .call(nodeLevel)

    if (res.vmError) return Promise.reject(new Error(res.vmError))

    const ripeDaysInSeconds = dayjs.duration(res.decoded[1], "days").asSeconds()
    return {
        minBalance: res.decoded[0],
        ripeSeconds: ripeDaysInSeconds.toString(),
        rewardRatio: res.decoded[2],
        rewardRatioX: res.decoded[3],
    }
}

const _delay = (milliseconds: number) => {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
    })
}
/**
 * Build an applyUpgrade clause to upgrade a node to a specific level
 * @param toLevel  the level to upgrade to
 * @returns the clause to apply the upgrade
 */
export const buildApplyUpgradeTx = (
    toLevel: string,
): Connex.Vendor.TxMessage[0] => {
    const clause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.applyUpgrade)
        .asClause(toLevel)

    return {
        ...clause,
        comment: "Upgrade your node to level " + toLevel,
        abi: TokenAuctionABI.functions.applyUpgrade,
    }
}

/**
 * Build a bid clause to purchase an auction
 * @param tokenId tokenId identifies an auction and token
 * @param price current price for an auction
 * @returns the clause to bid an auction
 */
export const buildBidTx = (
    tokenId: string,
    price: string,
): Connex.Vendor.TxMessage[0] => {
    const clause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.bid)
        .value(scaleNumberUp(price, 18))
        .asClause(tokenId)

    return {
        ...clause,
        comment: "Bid for an auction",
        abi: TokenAuctionABI.functions.bid,
    }
}

/**
 * Build an cancelUpgrade clause to cancel a node upgrade
 * @param tokenId the token id to cancel the upgrade
 * @returns the clause to cancel the upgrade
 */
export const buildCancelUpgradeTx = (
    tokenId: string,
): Connex.Vendor.TxMessage[0] => {
    const clause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.cancelUpgrade)
        .asClause(tokenId)

    return {
        ...clause,
        comment: "Cancel your node upgrade",
        abi: TokenAuctionABI.functions.cancelUpgrade,
    }
}

/**
 * General params to get the apply upgrade events
 * @param tokenId  the token id to get the events
 * @param auctionId  the auction id to get the events
 * @param order  the order of the events (asc or desc)
 * @param offset  the offset of the events
 * @param limit  the limit of the events (max 256)
 * @returns  the decoded applyUpgrade events
 */
export type GetEventsProps = {
    tokenId?: string
    auctionId?: number
    order?: "asc" | "desc"
    offset?: number
    limit?: number
    from?: number
    fromBlockNumber?: number
    topics?: Array<string | null>
}

/**
 *  Get the apply upgrade events of a token id (optional for better DX with react-query)
 * @param param0  the params to get the apply upgrade events (see GetEventsProps)
 * @returns the decoded applyUpgrade events
 */
export const getApplyUpgradeEvents = async ({
    tokenId,
    order = "asc",
    offset = 0,
    limit = 256,
}: GetEventsProps): Promise<ApplyUpgradeEvent[]> => {
    if (!tokenId) return Promise.reject(new Error("tokenId is required"))

    const applyUpgradeEvent = new abi.Event(
        TokenAuctionABI.events.NewUpgradeApply,
    )
    const topics = applyUpgradeEvent.encode({ _tokenId: tokenId })
    const events = await queryNodeEvents({
        topics,
        order,
        offset,
        limit,
    })

    const decodedEvents: ApplyUpgradeEvent[] = events.map(event => {
        const decoded = applyUpgradeEvent.decode(event.data, event.topics)
        return {
            applier: decoded._applier,
            applyBlockNumber: decoded._applyBlockNumber,
            applyTime: decoded._applyTime,
            level: decoded._level,
            tokenId: decoded._tokenId,
        }
    })

    return decodedEvents
}

/**
 * Get the level changed events of a token id (optional for better DX with react-query)
 * @param param0  the params to get the level changed events (see GetEventsProps)
 * @returns  the decoded levelChanged events
 */
export const getCancelUpgradeEvents = async ({
    tokenId,
    order = "asc",
    offset = 0,
    limit = 256,
}: GetEventsProps): Promise<CancelUpgradeEvent[]> => {
    if (!tokenId) return Promise.reject(new Error("tokenId is required"))

    const cancelUpgradeEvent = new abi.Event(
        TokenAuctionABI.events.CancelUpgrade,
    )
    const topics = cancelUpgradeEvent.encode({ _tokenId: tokenId })
    const events = await queryNodeEvents({
        topics,
        order,
        offset,
        limit,
    })

    const decodedEvents: CancelUpgradeEvent[] = events.map(event => {
        const decoded = cancelUpgradeEvent.decode(event.data, event.topics)
        return {
            tokenId: decoded._tokenId,
            owner: decoded._owner,
            meta: event.meta,
        }
    })

    return decodedEvents
}

/**
 * Get the level changed events of a token id (optional for better DX with react-query)
 * @param param0  the params to get the level changed events (see GetEventsProps)
 * @returns  the decoded levelChanged events
 */
export const getLevelChangedEvents = async ({
    tokenId,
    order = "asc",
    offset = 0,
    limit = 256,
}: GetEventsProps): Promise<LevelChangedEvent[]> => {
    if (!tokenId) return Promise.reject(new Error("tokenId is required"))

    const levelChangedEvent = new abi.Event(TokenAuctionABI.events.LevelChanged)
    const topics = levelChangedEvent.encode({ _tokenId: tokenId })
    const events = await queryNodeEvents({
        topics,
        order,
        offset,
        limit,
    })

    const decodedEvents: LevelChangedEvent[] = events.map(event => {
        const decoded = levelChangedEvent.decode(event.data, event.topics)
        return {
            tokenId: decoded._tokenId,
            owner: decoded._owner,
            fromLevel: decoded._fromLevel,
            toLevel: decoded._toLevel,
            meta: event.meta,
        }
    })

    return decodedEvents
}

/**
 * Retrieve the addAuctionWhiteList events for a specific auctionId
 *
 * @param auctionId
 * @returns decoded AddAuctionWhiteListEvent
 */
export const getAddAuctionWhiteListEvents = async ({
    auctionId,
    order = "asc",
    offset = 0,
    limit = 256,
}: GetEventsProps): Promise<AddAuctionWhiteListEvent[]> => {
    if (!auctionId) return Promise.reject(new Error("tokenId is required"))

    const eventToFilter = new abi.Event(
        TokenAuctionABI.events.AddAuctionWhiteList,
    )
    const topics = eventToFilter.encode({ _auctionId: auctionId })
    const events = await queryNodeEvents({
        topics,
        order,
        offset,
        limit,
    })

    const decodedEvents: AddAuctionWhiteListEvent[] = events.map(event => {
        const decoded = eventToFilter.decode(event.data, event.topics)
        return {
            auctionId: decoded._auctionId,
            tokenId: decoded._tokenId,
            candidate: decoded._candidate,
            meta: event.meta,
        }
    })

    return decodedEvents
}

/**
 * Build a clause to call the createSaleAuction function
 * startingPrice === endingPrice means that the auction is a fixed price sale
 *
 * @param tokenId
 * @param startingPrice
 * @param endingPrice
 * @param duration
 * @returns
 */
export const buildCreateAuctionSaleTx = (
    tokenId: string,
    startingPrice: number,
    endingPrice: number,
    duration: number,
): Connex.Vendor.TxMessage[0] => {
    const clause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.createSaleAuction)
        .asClause(
            tokenId,
            scaleNumberUp(startingPrice, 18), //convertToWei
            scaleNumberUp(endingPrice, 18), //convertToWei
            duration,
        )

    const convertedDuration = dayjs
        .duration(duration, "seconds")
        .format("D[ days] H[ hours]")
        .replace(/\b0 days\b/, "")
        .replace(/\b1 days\b/, "1 day")
        .replace(/\b0 hours\b/, "")

    const comment =
        startingPrice === endingPrice
            ? `Create a Fixed Price Auction sale with a price of ${startingPrice} VET and a duration of ${convertedDuration}`
            : `Create a Dutch auction with a starting price of ${startingPrice} VET, an ending price of ${endingPrice} VET and a duration of ${convertedDuration}`

    return {
        ...clause,
        comment,
        abi: TokenAuctionABI.functions.createSaleAuction,
    }
}

/**
 * Build a clause to call the createDutchAuction function
 * (both for dutch auction, fixed sale and direct sale)
 *
 * @param tokenId
 * @returns
 */
export const buildCancelSaleAuctionTx = (
    tokenId: string,
): Connex.Vendor.TxMessage[0] => {
    const clause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.cancelAuction)
        .asClause(tokenId)

    return {
        ...clause,
        comment: "Cancel sale",
        abi: TokenAuctionABI.functions.cancelAuction,
    }
}

/**
 * Build a clause to call the createDirectionalSaleAuction function
 *
 * @param tokenId
 * @param price
 * @param receiverAddress
 * @param duration
 */
export const buildCreateDirectSaleTx = (
    tokenId: string,
    price: number,
    receiverAddress: string,
    duration: number,
): Connex.Vendor.TxMessage[0] => {
    const clause = connex.thor
        .account(VNT_MAIN_CONTRACT)
        .method(TokenAuctionABI.functions.createDirectionalSaleAuction)
        .asClause(
            tokenId,
            scaleNumberUp(price, 18), //convertToWei
            duration,
            receiverAddress,
        )

    const convertedDuration = dayjs
        .duration(duration, "seconds")
        .format("D[ days] H[ hours]")
        .replace(/\b0 days\b/, "")
        .replace(/\b1 days\b/, "1 day")
        .replace(/\b0 hours\b/, "")

    return {
        ...clause,
        comment: `Create a direct sale for address ${receiverAddress} with a price of ${price} VET and a duration of ${convertedDuration}`,
        abi: TokenAuctionABI.functions.cancelAuction,
    }
}
