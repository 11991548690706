import { Image, ImageProps, useColorModeValue } from "@chakra-ui/react"
import React from "react"

type Props = {
    variant?: "dark" | "light" | "auto"
} & ImageProps
export const VechainPictogram: React.FC<Props> = ({
    variant = "auto",
    ...props
}) => {
    const lightModeUrl = "/images/logo/pictogram.png"
    const darkModeUrl = "/images/logo/pictogram_white.png"
    const autoLogoUrl = useColorModeValue(lightModeUrl, darkModeUrl)
    const logoUrl =
        variant === "auto"
            ? autoLogoUrl
            : variant === "dark"
              ? darkModeUrl
              : lightModeUrl
    return (
        <Image
            objectFit={"cover"}
            height={"full"}
            alt="Vechain pictogram"
            src={logoUrl}
            {...props}
        />
    )
}
