import { Network } from "@/models"
import { defaultNetworks } from "@/constants"

export const getWalletConnectChainId = (network: Network) =>
    `vechain:${network.genesis.id.slice(-32)}`

export const fromChainId = (chainId: string): Network["name"] => {
    for (const [key, value] of Object.entries(defaultNetworks)) {
        if (value.genesis.id.slice(-32) === chainId) {
            return key
        }
    }

    throw new Error("Invalid chainId")
}
