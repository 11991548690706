import {
    Alert,
    AlertIcon,
    Button,
    Card,
    CardBody,
    HStack,
    Heading,
    Icon,
    Skeleton,
    Spinner,
    Text,
    VStack,
} from "@chakra-ui/react"
import {
    BalanceRequirement,
    BaseModal,
    NodeParametersInfo,
    TransactionHelperText,
} from ".."
import { useApplyUpgrade, useBalance, useCheckMobileScreen } from "@/hooks"
import {
    AnalyticsEventsEnum,
    EconomicNodeStrengthLevel,
    NodeParameters,
    VET,
} from "@/constants"
import { useAccountStore, useCurrencyStore } from "@/store"
import { useAccountBalance, useVetExchangeRate } from "@/api/hooks"
import { BoltIcon } from "@heroicons/react/24/solid"
import dayjs from "dayjs"
import { useMemo } from "react"
import BigNumber from "bignumber.js"
import { TokenMetadata } from "@/models"
import {
    CannotUpgradeNodeReason,
    cannotUpgradeReasonToText,
} from "@/utils/RewardsUtils/RewardsUtils"
import { AnalyticsUtils } from "@/utils"

const firstNodeLevel = "1"
const firstNodeName = EconomicNodeStrengthLevel[firstNodeLevel]
const firstNodeParams = NodeParameters[firstNodeLevel]
interface IApplyForEconomicNodeModalProps {
    isOpen: boolean
    onClose: () => void
    tokenId: string
    metadata?: TokenMetadata
}
export const ApplyForEconomicNodeModal: React.FC<
    IApplyForEconomicNodeModalProps
> = ({ isOpen, onClose, tokenId, metadata }) => {
    const { isMobile } = useCheckMobileScreen()

    const header = (
        <VStack
            spacing={0}
            p={4}
            align={"flex-start"}
            data-cy="apply-for-economic-modal__title">
            <HStack alignItems={"center"} spacing={1}>
                <Icon as={BoltIcon} boxSize={5} />
                <Heading size="md">{"Apply for an economic node"}</Heading>
            </HStack>
            <Text fontSize={"sm"} fontWeight={"light"} color="gray.500">
                {"Each economic node comes with governance rights"}
            </Text>
        </VStack>
    )

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            header={header}
            headerStyle={{ p: 0 }}
            size={isMobile ? "full" : "lg"}
            body={
                <ApplyForEconomicNodeBody
                    onClose={onClose}
                    tokenId={tokenId}
                    metadata={metadata}
                />
            }
        />
    )
}

type Props = Omit<IApplyForEconomicNodeModalProps, "isOpen">

const ApplyForEconomicNodeBody: React.FC<Props> = ({
    onClose,
    tokenId,
    metadata,
}) => {
    const { currency, numberFormat } = useCurrencyStore()
    const { data: exchangeRate } = useVetExchangeRate(currency.coingeckoID)

    const { account } = useAccountStore()
    const { data: accountBalance } = useAccountBalance(account?.address)

    const cannotApplyReason = useMemo(() => {
        if (!accountBalance) return ""
        if (tokenId !== "0") return "You already own a node"
        if (metadata?.onUpgrade)
            return cannotUpgradeReasonToText[CannotUpgradeNodeReason.onUpgrade]
        const isMeetingBalanceRequirement = BigNumber(
            accountBalance?.balance,
        ).isGreaterThanOrEqualTo(BigNumber(firstNodeParams.minBalance))
        if (!isMeetingBalanceRequirement)
            return `${
                cannotUpgradeReasonToText[
                    CannotUpgradeNodeReason.balanceRequirement
                ]
            } ${firstNodeName}`
    }, [accountBalance, metadata])

    const ripeDays = useMemo(
        () =>
            dayjs
                .duration(parseInt(firstNodeParams.ripeSeconds), "seconds")
                .asDays(),
        [],
    )

    const expectedUpgradeCompletionDate = useMemo(
        () => dayjs().add(parseInt(firstNodeParams.ripeSeconds), "seconds"),
        [],
    )

    const { parsedBalance: parsedMinBalance } = useBalance({
        balance: firstNodeParams.minBalance,
        exchangeRate,
        token: VET,
        numberFormat: numberFormat.locale,
        includeDecimals: true,
    })

    const { sendTransaction, sendTransactionLoading, isTxReceiptLoading } =
        useApplyUpgrade({
            toLevel: firstNodeLevel,
            onSuccess: () => {
                onClose()
                AnalyticsUtils.trackEvent(
                    AnalyticsEventsEnum.APPLY_FOR_ECONOMIC_NODE_SUCCESSFUL,
                )
            },
            onSuccessMessageTitle: "Application successful",
        })

    const isLoading = sendTransactionLoading || isTxReceiptLoading

    const handleOnClick = () => {
        sendTransaction()
        AnalyticsUtils.trackEvent(AnalyticsEventsEnum.APPLY_UPGRADE_CONFIRMED)
    }

    if (!account) return <></>
    return (
        <VStack spacing={4} align="stretch" data-cy="apply-for-node-modal">
            <NodeParametersInfo level={firstNodeLevel} />
            <Card w={"full"}>
                <CardBody>
                    <BalanceRequirement
                        address={account?.address}
                        balanceRequirement={parsedMinBalance}
                        showRequiredVET={true}
                        showRiskOwnershipMessage={false}
                        tagSize={"md"}
                        headingSize={"sm"}
                        iconSize={6}
                        showAccountBalance={true}
                    />
                </CardBody>
            </Card>

            {cannotApplyReason ? (
                <Skeleton isLoaded={!!accountBalance}>
                    <Alert
                        data-cy="cannot-upgrade-alert"
                        status="error"
                        variant="subtle"
                        size="sm"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <AlertIcon boxSize={4} />
                        <Text fontSize="sm">
                            {cannotApplyReason ?? "loading..."}
                        </Text>
                    </Alert>
                </Skeleton>
            ) : (
                <Skeleton isLoaded={!!accountBalance}>
                    <Alert
                        data-cy="upgrade-time-alert"
                        status="warning"
                        // variant="subtle"
                        size="sm"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <AlertIcon boxSize={4} />
                        <Text fontSize={"sm"}>
                            {`The application will take ${ripeDays} days and will be completed on ${expectedUpgradeCompletionDate?.format(
                                "DD/MM/YYYY",
                            )}`}
                        </Text>
                    </Alert>
                </Skeleton>
            )}
            <VStack spacing={0.5} w="full">
                <Button
                    data-cy="apply-for-node-button"
                    size={["lg", "lg", "md"]}
                    w={["full", "50%"]}
                    alignSelf={["center"]}
                    colorScheme="primary"
                    onClick={handleOnClick}
                    isDisabled={
                        isLoading || !!cannotApplyReason || !accountBalance
                    }
                    transition={"all 0.2s ease-in-out"}>
                    {sendTransactionLoading ? (
                        <HStack spacing={1}>
                            <Spinner
                                size="sm"
                                data-cy="apply-for-node-button__spinner"
                            />
                            <Text>{"Applying..."}</Text>
                        </HStack>
                    ) : (
                        "Apply"
                    )}
                </Button>

                <TransactionHelperText
                    transactionIsLoading={sendTransactionLoading}
                    receiptIsLoading={isTxReceiptLoading}
                />
            </VStack>
        </VStack>
    )
}
