import { keyframes } from "@emotion/react"

export const animatedgradient = keyframes({
    "0%": {
        backgroundPosition: "0% 50%",
    },
    "50%": {
        backgroundPosition: "100% 50%",
    },
    "100%": {
        backgroundPosition: "0% 50%",
    },
})

export const animatedgradientVertical = keyframes({
    "0%": {
        backgroundPosition: "50% 0%",
    },
    "50%": {
        backgroundPosition: "50% 100%",
    },
    "100%": {
        backgroundPosition: "50% 0%",
    },
})

export const bgSpin = keyframes({
    to: {
        angle: "1turn",
    },
})

export const animatedScale = keyframes({
    "0%": {
        transform: "scale(1.25)",
    },
    "50%": {
        transform: "scale(1)",
    },
    "100%": {
        transform: "scale(1.25)",
    },
})
