import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { LocalStorageService } from "@/services"

interface State {
    notificationsLookbackLimit: number
    lastTimeClosedLevelChangedAlert: number | null
    lastTimeClosedUpgradeFailedAlert: number | null
    hasClosedMaxLevelAlert: boolean
    hasClosedSuccessfulAuctionAlert: boolean
    setLastTimeClosedLevelChangedAlert: (
        lastTimeClosedLevelChangedAlert: number | null,
    ) => void
    setHasClosedMaxLevelAlert: (hasClosedMaxLevelAlert: boolean) => void
    setLastTimeClosedUpgradeFailedAlert: (
        lastTimeClosedUpgradeFailedAlert: number | null,
    ) => void
    setHasClosedSuccessfulAuctionAlert: (
        hasClosedSuccessfulAuctionAlert: boolean,
    ) => void
}

export const useNotificationsStore = create<State>()(
    devtools(
        persist(
            (set, get) => ({
                notificationsLookbackLimit: 30,
                lastTimeClosedLevelChangedAlert: null,
                lastTimeClosedUpgradeFailedAlert: null,
                hasClosedMaxLevelAlert: false,
                hasClosedSuccessfulAuctionAlert: false,
                setLastTimeClosedLevelChangedAlert:
                    lastTimeClosedLevelChangedAlert => {
                        set({ ...get(), lastTimeClosedLevelChangedAlert })
                    },
                setHasClosedMaxLevelAlert: hasClosedMaxLevelAlert => {
                    set({ ...get(), hasClosedMaxLevelAlert })
                },
                setHasClosedSuccessfulAuctionAlert:
                    hasClosedSuccessfulAuctionAlert => {
                        set({ ...get(), hasClosedSuccessfulAuctionAlert })
                    },
                setLastTimeClosedUpgradeFailedAlert:
                    lastTimeClosedUpgradeFailedAlert => {
                        set({ ...get(), lastTimeClosedUpgradeFailedAlert })
                    },
            }),
            {
                name: LocalStorageService.NOTIFICATIONS_STORAGE_KEY,
            },
        ),
    ),
)
