import { Currency } from "@/models"

export const CURRENCIES: Currency[] = [
    {
        name: "EUR",
        symbol: "€",
        coingeckoID: "eur",
    },
    {
        name: "USD",
        symbol: "$",
        coingeckoID: "usd",
    },
]

export const DEFAULT_CURRENCY = CURRENCIES[0]
