import { BoxWithGradientBorder } from "@/components"
import {
    NodeStrengthLevel,
    NodeStrengthLevelToImage,
    VechainLinearGradient,
} from "@/constants"
import { TokenParams } from "@/models"
import {
    Box,
    HTMLChakraProps,
    Icon,
    Image,
    Tag,
    VStack,
    useColorMode,
    useColorModeValue,
    useToken,
} from "@chakra-ui/react"
import { CheckBadgeIcon } from "@heroicons/react/20/solid"
import React from "react"

type Props = {
    level: string
    display?: "flex" | "grid"
    variant?:
        | "default"
        | "gradient"
        | "disabled"
        | "disabledGradient"
        | "gradientUpgrade"
    renderTokenName?: boolean
    wrapperProps?: HTMLChakraProps<"div">
    imageProps?: HTMLChakraProps<"img">
    disabledOpacity?: number
}

/**
 *  Renders the token image with a gradient border if isOwnedNode is true
 * @param level TokenParams - the level of the token
 * @param variant "default" | "gradient" | "disabled" | "disabledGradient" - the variant of the token
 * @param width string - the width of the token image
 * @param renderTokenName boolean - whether to render the token name
 * @param disabledOpacity number - the opacity of the token if disabled
 * @returns  React.ReactNode
 */
export const TokenImageBox: React.FC<Props> = ({
    level,
    variant = "default",
    renderTokenName = true,
    display = "flex",
    wrapperProps = {
        width: 20,
    },
    imageProps = {
        borderRadius: "var(--chakra-sizes-2)",
        width: "full",
    },
    disabledOpacity = 0.7,
}) => {
    const { colorMode } = useColorMode()
    const isDark = colorMode === "dark"
    const isOpaque = ["disabled", "disabledGradient"].includes(variant)

    //TODO : should apply a different style when disabledGradient ?

    const opaquedColor = useColorModeValue("gray.300", "gray.700")
    const [yellow300, orange300, red300] = useToken(
        // the key within the theme, in this case `theme.colors`
        "colors",
        // the subkey(s), resolving to `theme.colors.red.100`
        ["yellow.300", "orange.300", "red.300"],
        // a single fallback or fallback array matching the length of the previous arg
    )

    const upgradeLinearGradient = `linear-gradient(60deg, ${yellow300} 13%, ${orange300} 60%, ${red300} 100%)`
    const gradient =
        variant === "gradient"
            ? VechainLinearGradient(isDark)
            : variant === "gradientUpgrade"
              ? upgradeLinearGradient
              : undefined

    return (
        <VStack
            data-cy={`token-${level}-image-box`}
            key={`${level}`}
            position={"relative"}
            opacity={1}
            zIndex={2}
            display={display}
            alignSelf={"center"}>
            <TokenImageWrapper gradient={gradient} {...wrapperProps}>
                {isOpaque && (
                    <Box
                        borderRadius={"var(--chakra-sizes-2)"}
                        position={"absolute"}
                        w="full"
                        h="full"
                        opacity={disabledOpacity}
                        top={0}
                        left={0}
                        zIndex={1}
                        bg={opaquedColor}
                    />
                )}

                <Image
                    src={NodeStrengthLevelToImage[level]}
                    alt={`
                token-image-${level}
                `}
                    {...imageProps}
                />
            </TokenImageWrapper>
            {renderTokenName && (
                <Box position={"relative"}>
                    {isOpaque && (
                        <Box
                            borderRadius={"md"}
                            position={"absolute"}
                            w="full"
                            h="full"
                            opacity={disabledOpacity}
                            top={0}
                            left={0}
                            zIndex={1}
                            bg={opaquedColor}
                        />
                    )}
                    <Tag
                        display={"flex"}
                        gap={1}
                        flexDir={"row"}
                        colorScheme={"gray"}>
                        {gradient && <Icon as={CheckBadgeIcon} />}
                        {NodeStrengthLevel[level]}
                    </Tag>
                </Box>
            )}
        </VStack>
    )
}

type TokenImageWrapperProps = {
    gradient?: string
    params?: TokenParams
    children: React.ReactNode
} & Omit<HTMLChakraProps<"div">, "borderWidth" | "borderColor">

/**
 * Wraps the token image with a gradient border if isSelected is true
 * @param isGradient boolean - whether to render the gradient border
 * @param params TokenParams - the params of the token
 * @param children React.ReactNode - the token image
 * @returns React.ReactNode
 */
const TokenImageWrapper: React.FC<TokenImageWrapperProps> = ({
    gradient,
    children,
    ...otherProps
}) => {
    if (gradient)
        return (
            <BoxWithGradientBorder
                borderWidth={2}
                renderInnerBorder={true}
                borderColor={"var(--tag-bg)"}
                gradient={gradient}
                {...otherProps}>
                {children}
            </BoxWithGradientBorder>
        )

    return (
        <Box
            // borderWidth={2}
            // background={"var(--tag-bg)"}
            // borderRadius={"var(--chakra-sizes-2)"}
            position={"relative"}
            {...otherProps}>
            {children}
        </Box>
    )
}
