import { CoreTypes } from "@walletconnect/types/dist/types/core/core"
import { genesisesId } from "@/constants"
import ViteUtils from "@/utils/ViteUtils"

if (!import.meta.env.VITE_PUBLIC_PROJECT_ID)
    throw new Error("`VITE_PUBLIC_PROJECT_ID` env variable is missing.")

export const DEFAULT_PROJECT_ID = import.meta.env.VITE_PUBLIC_PROJECT_ID

export const DEFAULT_LOGGER =
    import.meta.env.VITE_WALLET_CONNECT_DEBUGGER === "true" ? "debug" : ""

export const DEFAULT_APP_METADATA: CoreTypes.Metadata = {
    name: "Official vechain Rewards dApp",
    description:
        "Explore the Official vechain Rewards dApp - Your solution to effortlessly manage x-nodes, claim rewards and track your reward history. Get started now!",
    url: window.location.origin,
    icons: ["https://i.ibb.co/zb85hsL/vechain-vet-logo512.png"],
}

export const SUPPORTED_CHAINS = ViteUtils.isMode("production", "demo")
    ? [`vechain:${genesisesId.main.slice(-32)}`]
    : [`vechain:${genesisesId.solo.slice(-32)}`]

export enum DEFAULT_METHODS {
    REQUEST_TRANSACTION = "thor_sendTransaction",
    SIGN_CERTIFICATE = "thor_signCertificate",
}
