import { NumberFormat } from "@/models"
import { currencyCodes } from "@/constants"

export const NumberFormats: NumberFormat[] = [
    {
        currency: "USD",
        locale: "en-US",
        formatString: "123,456.79",
        img: "/images/currency/en-US.webp",
    },
    {
        currency: "EUR",
        locale: "it-IT",
        formatString: "123.456,79",
        img: "/images/currency/it-IT.webp",
    },
    {
        currency: "EUR",
        locale: "en-IE",
        formatString: "123,456.79",
        img: "/images/currency/en-IE.webp",
    },
    {
        currency: "AUD",
        formatString: "123,456.79",
        locale: "en-AU",
        img: "/images/currency/en-AU.webp",
    },
]

const navigatorLocale: NumberFormat =
    currencyCodes.find(el => el.locale === window.navigator.language) ||
    NumberFormats[0]
export const DEFAULT_NUMBER_FORMAT: NumberFormat = {
    currency: navigatorLocale.currency,
    locale: window.navigator.language,
    formatString: navigatorLocale.formatString,
}
