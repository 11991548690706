import { genesises } from "@/constants/Thor"

/**
 * The type of network that we are connected to (indentified by the genesis block)
 * */
export enum NETWORK_TYPE {
    MAIN = "main",
    TEST = "test",
    SOLO = "solo",
}

/**
 * A model for the VechainThor network that we are connected to
 * @field `id` - Unique ID for this network
 * @field `defaultNet` - If the network is a default network
 * @field `name` - A name for this network
 * @field `type` - What type of network is it? `main, test, solo or custom`
 * @field `urls` - A list of URLs for this network
 * @field `currentUrl` - The current URL that we are connected to
 * @field `explorerUrl` - The explorer URL for this network
 * @field `genesis` - The genesis block for the network
 */
export type Network = {
    id: string
    defaultNet: boolean
    name: string
    type: NETWORK_TYPE
    urls: string[]
    currentUrl: string
    explorerUrl?: string
    genesis: typeof genesises.main
}
