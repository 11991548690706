import { TokenMetadata } from "@/models/Rewards.ts"

/**
 * If startingPrice  === to endingPrice, the auction is dutch auction,
 * otherwise it's fixed price auction
 */
export enum AuctionType {
    FIXED_PRICE = "Fixed Price",
    DUTCH = "Dutch",
    DIRECT_SALE = "Direct Sale",
}

/**
 * The response of the getAuction request to the smart contract.
 *
 * @property auctionId the id of the auction
 * @property tokenOwner the seller of the auction
 * @property startingPrice the starting price of the auction
 * @property endingPrice the ending price of the auction
 * @property duration the duration of the auction
 * @property startedAt the time the auction has started
 */
export interface AuctionResponse {
    auctionId: number
    tokenOwner: string
    startingPrice: string
    endingPrice: string
    duration: number
    startedAt: number
}

/**
 * Representation of the auction
 *
 * Extends the AuctionResponse with the following properties:
 * @property tokenId the id of the token
 * @property currentPrice the current price of the auction
 * @property type the type of the auction
 * @property tokenLevel the level of the token
 * @property fiatPrice the price of the token in fiat
 * @property if the auction is whitelisted (direct sale)
 * @property whitelistedAddress the address whitelisted for the direct sale
 */
export interface Auction extends AuctionResponse {
    tokenId: string
    type: AuctionType
    currentPrice: string
    tokenLevel?: string
    fiatPrice?: number
    isWhitelisted?: boolean
    whitelistedAddress?: string
}

export interface AuctionWithMetadata extends Auction {
    tokenMetadata: TokenMetadata
}

/**
 * The possible durations of the auction in seconds
 */
export enum AuctionDuration {
    TWO_HOURS = 7200,
    FOUR_HOURS = 14400,
    SIX_HOURS = 21600,
    TWELVE_HOURS = 43200,
    ONE_DAY = 86400,
    THREE_DAYS = 259200,
    ONE_WEEK = 604800,
}
